import { Button, Modal } from 'react-bootstrap';
import { Typography } from 'Components/atoms';
import { AiOutlineClose } from '@react-icons/all-files/ai/AiOutlineClose';
import { CANCEL } from 'Core-utils/constants/constants';
import classNames from 'classnames';
import { useI18n } from 'Hooks/useI18n';
import './styles.scss';

interface ConfirmationModalProps {
    cancelButtonDisabled?: boolean;
    modalTitle: string;
    modalContent: React.ReactNode;
    onPrimaryButtonClick: () => void;
    primaryButtonLabel: string;
    primaryButtonDisabled?: boolean;
    show: boolean;
    onClose: () => void;
    buttonVariant: 'primary' | 'danger' | 'disabled';
    modalVariant?: 'default' | 'custom';
    matchingEngagements?: string[];
    noOfUsers?: number;
}

const ConfirmationModal = ({
    buttonVariant,
    cancelButtonDisabled,
    modalContent,
    modalTitle,
    modalVariant,
    primaryButtonLabel,
    primaryButtonDisabled,
    show,
    onClose,
    onPrimaryButtonClick,
    matchingEngagements,
    noOfUsers,
}: ConfirmationModalProps) => {
    const { t } = useI18n();
    const buttonClassName = classNames('text-uppercase text-text-black confirmation-modal__primary-button body1', {
        'bg-primary-500': buttonVariant === 'primary',
        'bg-secondary-accent-red-4': buttonVariant === 'danger',
        'bg-primary-500 opacity-50': buttonVariant === 'disabled',
    });
    const modalClassName = `custom-width-${modalVariant ?? 'default'}`;
    return (
        <Modal show={show} className="confirmation-modal" centered dialogClassName={modalClassName} onHide={onClose}>
            <Modal.Header>
                <Modal.Title className="text-uppercase">
                    <Typography variant="subtitle4" color="text-high-emphasis">
                        {modalTitle}
                    </Typography>
                </Modal.Title>
                <AiOutlineClose className="text-text-high-emphasis confirmation-modal__icon" onClick={onClose} />
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex flex-column gap-4 w-100">
                    <Typography variant="body5" color="text-high-emphasis">
                        {t('SEND_ENGAGEMENT_TEXT') + noOfUsers + ' users ?'}
                    </Typography>
                    <div className="d-flex flex-column gap-2 w-100">
                        {matchingEngagements && (
                            <div className="d-flex flex-column gap-3 w-100">
                                <Typography variant="body2" color="text-high-emphasis">
                                    {t('MATCHING_ENGAGEMENTS')}
                                </Typography>
                                <div className="d-flex flex-column gap-2 confirmation-modal__engagement-cont custom-scrollbar w-100">
                                    {matchingEngagements.map((engagement, index) => (
                                        <Typography key={index} variant="body5" color="text-high-emphasis">
                                            {engagement}
                                        </Typography>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                {modalContent}
                <div className="d-flex confirmation-modal__button justify-content-center">
                    <Button
                        className="text-uppercase text-text-high-emphasis confirmation-modal__cancel-button body1 me-4"
                        disabled={cancelButtonDisabled}
                        onClick={onClose}
                    >
                        {CANCEL}
                    </Button>
                    <Button
                        className={buttonClassName}
                        disabled={primaryButtonDisabled}
                        onClick={onPrimaryButtonClick}
                        data-testid="primary-btn"
                    >
                        {primaryButtonLabel}
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ConfirmationModal;
