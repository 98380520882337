import { FindingSpecDto } from '@ampsec/platform-client';
import { RiDeleteBin6Line } from '@react-icons/all-files/ri/RiDeleteBin6Line';
import { Chip } from 'Components/atoms';
import { CustomTooltip } from 'Components/atoms';
import { Typography } from 'Components/atoms';
import { DESCRIPTION, FINDING_DISPLAY_NAME, FINDING_NAME, SEVERITY_TEXT } from 'Core-utils/constants/constants';
import { TableColumn } from 'react-data-table-component';
import './styles.scss';

export interface FindingsConfigColumnsType {
    id: string;
    findingName: string;
    eventType?: string;
    severity: string;
    finding: string;
    description: string;
}

interface FindingsConfigTableProps {
    handleDelete: (id: string) => void;
    onNameClick: (row: FindingSpecDto) => void;
}

export const FINDINGS_CONFIG_COLUMNS = (
    handleDelete: FindingsConfigTableProps['handleDelete'],
    onNameClick: FindingsConfigTableProps['onNameClick'],
): TableColumn<FindingSpecDto>[] => [
    {
        id: 'name',
        name: <Typography variant="body2">{FINDING_NAME}</Typography>,
        cell: (row: FindingSpecDto) => (
            <div onClick={() => onNameClick(row)} className="findings-config__name">
                <Typography variant="body5">{row.name}</Typography>
            </div>
        ),
    },
    {
        id: 'severity',
        name: <Typography variant="body2">{SEVERITY_TEXT}</Typography>,
        cell: (row: FindingSpecDto) => {
            return (
                <div className={`text-uppercase`}>
                    <Chip value={row.severity} variant="resolved_issue_variant" />
                </div>
            );
        },
        width: '12rem',
    },
    {
        id: 'displayValue',
        name: <Typography variant="body2">{FINDING_DISPLAY_NAME}</Typography>,
        cell: (row: FindingSpecDto) => (
            <CustomTooltip
                id={`finding-${row.id}`}
                label={`${row.displayValue ?? ''}`}
                labelColor="primary"
                labelVariant="subtitle3"
                overlayPlacement="left"
                overlayContainerClassName="d-flex  overflow-hidden w-100"
                tooltipClassName="color-white text-white"
            >
                <Typography variant="body5">{row.displayValue}</Typography>
            </CustomTooltip>
        ),
        width: '25rem',
        center: false,
    },
    {
        id: 'description',
        name: <Typography variant="body2">{DESCRIPTION}</Typography>,
        cell: (row: FindingSpecDto) => (
            <CustomTooltip
                id={`description-${row.id}`}
                label={`${row.description ?? ''}`}
                labelColor="primary"
                labelVariant="subtitle3"
                overlayPlacement="left"
                overlayContainerClassName="d-flex  overflow-hidden w-100"
                tooltipClassName="color-white text-white"
            >
                <Typography variant="body5">{row.description as string}</Typography>
            </CustomTooltip>
        ),
        width: '25rem',
        center: false,
    },
    {
        id: 'delete',
        cell: (row: FindingSpecDto) => (
            <RiDeleteBin6Line
                className="text-text-low-emphasis"
                cursor={'pointer'}
                onClick={() => handleDelete(row.id)}
                size={16}
            />
        ),
        width: '10rem',
    },
];
