import { useMemo, useState } from 'react';
import classNames from 'classnames';
import { Dropdown } from 'react-bootstrap';
import { RiArrowDownSLine } from '@react-icons/all-files/ri/RiArrowDownSLine';
import { SelectCallback } from '@restart/ui/esm/types';
import { MdKeyboardArrowRight } from '@react-icons/all-files/md/MdKeyboardArrowRight';
import { MdKeyboardArrowLeft } from '@react-icons/all-files/md/MdKeyboardArrowLeft';
import { CgPushChevronLeft } from '@react-icons/all-files/cg/CgPushChevronLeft';
import { CgPushChevronRight } from '@react-icons/all-files/cg/CgPushChevronRight';
import { CustomPaginationProps, PaginationComponentProps } from 'Core-utils/types/types';
import { Typography } from 'Components/atoms';
import { BETWEEN_PREPOSITION, ROWS_PER_PAGE } from 'Core-utils/constants/constants';

const DEFAULT_NUMBER_OF_PAGES_TO_SHOW = 3;

const PaginationComponent = ({
    onFirstPageClick,
    onLastPageClick,
    onNextButtonClick,
    onBackButtonClick,
    previousDisabled,
    nextDisabled,
    pageItems,
    currentPage,
    onPageNumberClick,
    pages,
}: PaginationComponentProps) => {
    return (
        <div className="d-flex flex-row align-items-center justify-content-evenly gap-3">
            <div className="page-item d-flex gap-1">
                <CgPushChevronLeft
                    className={classNames('table-skeleton__arrow', {
                        disabled: previousDisabled,
                    })}
                    onClick={onFirstPageClick}
                />
                <MdKeyboardArrowLeft
                    className={classNames('page-link table-skeleton__arrow', {
                        disabled: previousDisabled,
                    })}
                    onClick={onBackButtonClick}
                />
            </div>
            {pageItems.map((page) => {
                const className = classNames('page-item', {
                    active: page === currentPage,
                    disabled: page > pages,
                });

                return (
                    <div key={page} className={className}>
                        <button
                            className="page-link rounded-1"
                            onClick={onPageNumberClick}
                            value={page}
                            disabled={page > pages}
                            data-testid={`page-${page}`}
                        >
                            {page}
                        </button>
                    </div>
                );
            })}
            <div className="page-item d-flex gap-1">
                <MdKeyboardArrowRight
                    className={classNames('page-link table-skeleton__arrow', {
                        disabled: nextDisabled,
                    })}
                    onClick={onNextButtonClick}
                />
                <CgPushChevronRight
                    className={classNames('table-skeleton__arrow', {
                        disabled: nextDisabled,
                    })}
                    onClick={onLastPageClick}
                />
            </div>
        </div>
    );
};

const TableRowDropdown = ({
    onSelectHandler,
    numberOfRows,
    rowsPerPageOptions,
    rowCount,
}: {
    onSelectHandler: SelectCallback | undefined;
    numberOfRows: number;
    rowsPerPageOptions: number[];
    rowCount: number;
}) => {
    const firstOptionGreaterThanRowCount = rowsPerPageOptions.find((option) => option >= rowCount);
    const filteredOptions = rowsPerPageOptions.filter((rowsPerPageOption) =>
        firstOptionGreaterThanRowCount ? rowsPerPageOption <= firstOptionGreaterThanRowCount : true,
    );
    return (
        <Dropdown onSelect={onSelectHandler} drop="up">
            <Dropdown.Toggle className="text-text-high-emphasis d-flex justify-content-around align-items-center p-2">
                <Typography variant="subtitle3" color="text-high-emphasis">
                    {numberOfRows}
                </Typography>
                <RiArrowDownSLine className={'dropdown__dropdown-icon'} />
            </Dropdown.Toggle>
            <Dropdown.Menu className="bg-structural-CardBG table-row-menu p-0 border border-structural-stroke-300 overflow-hidden">
                {filteredOptions.map((rowsPerPageOption, idx) => (
                    <Dropdown.Item
                        className="text-text-high-emphasis d-flex justify-content-center table-row-item"
                        eventKey={rowsPerPageOption}
                        key={idx}
                        tabIndex={idx}
                    >
                        {rowsPerPageOption}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};

const getAvailablePageNumbers = (
    pages: number,
    currentPage: number,
    numberOfPagesToShow: number = DEFAULT_NUMBER_OF_PAGES_TO_SHOW,
): number[] => {
    if (pages === 0 || currentPage === 0) {
        return [];
    }

    const results: number[] = [];
    let firstPage = 1;
    if (pages > numberOfPagesToShow && currentPage !== 1) {
        if (currentPage === pages) {
            firstPage = currentPage - 2;
        } else {
            firstPage = currentPage - 1;
        }
    }

    for (let i = firstPage; i < firstPage + numberOfPagesToShow; i++) {
        results.push(i);
    }

    return results;
};

const CustomPagination = ({
    rowsPerPage,
    rowCount,
    onChangePage,
    onChangeRowsPerPage,
    currentPage,
    rowsPerPageOptions,
}: CustomPaginationProps) => {
    const pages = rowsPerPage > 0 ? Math.ceil(rowCount / rowsPerPage) : 0;
    const nextDisabled = currentPage === pages;
    const previousDisabled = currentPage === 1;

    const [numberOfRows, setNumberOfRows] = useState(rowsPerPage);

    const fromRow = (currentPage - 1) * numberOfRows + 1;
    const toRow = Math.min(currentPage * numberOfRows, rowCount);

    const onSelectHandler = (eventKey: any, _event: Object) => {
        setNumberOfRows(eventKey);
        onChangeRowsPerPage(eventKey, currentPage);
    };

    const memoizedPageItems = useMemo(
        () => getAvailablePageNumbers(pages, currentPage, DEFAULT_NUMBER_OF_PAGES_TO_SHOW),
        [pages, currentPage],
    );
    const handleBackButtonClick = () => {
        onChangePage(currentPage - 1, rowCount);
    };

    const handleNextButtonClick = () => {
        onChangePage(currentPage + 1, rowCount);
    };

    const handlePageNumber = (e: React.MouseEvent<HTMLElement>) => {
        onChangePage(Number((e.target as HTMLButtonElement).value), rowCount);
    };
    const handleGoToFirstPage = () => {
        onChangePage(1, rowCount);
    };

    const handleGoToLastPage = () => {
        onChangePage(pages, rowCount);
    };

    return (
        <nav className="bg-structural-CardBG d-flex flex-row justify-content-between align-items-center text-text-high-emphasis p-2 ps-3 pe-3 table-skeleton__pagination">
            <div className="rows-options d-flex justify-content-center align-items-center gap-3">
                <Typography variant="body7" color="text-medium-emphasis" style={{ marginBottom: '0%' }}>
                    {ROWS_PER_PAGE}
                </Typography>
                <Dropdown className="border border-structural-stroke-300 rounded" onSelect={onSelectHandler}>
                    <TableRowDropdown
                        onSelectHandler={onSelectHandler}
                        numberOfRows={numberOfRows}
                        rowsPerPageOptions={rowsPerPageOptions || []}
                        rowCount={rowCount}
                    />
                </Dropdown>
                <div className="d-flex gap-2">
                    <Typography variant="subtitle3">{`${fromRow} - ${toRow} `}</Typography>

                    <Typography variant="subtitle3">{BETWEEN_PREPOSITION}</Typography>

                    <Typography variant="subtitle3">{rowCount}</Typography>
                </div>
            </div>
            <PaginationComponent
                onFirstPageClick={handleGoToFirstPage}
                onLastPageClick={handleGoToLastPage}
                onPageNumberClick={handlePageNumber}
                onNextButtonClick={handleNextButtonClick}
                onBackButtonClick={handleBackButtonClick}
                previousDisabled={previousDisabled}
                nextDisabled={nextDisabled}
                pageItems={memoizedPageItems}
                currentPage={currentPage}
                pages={pages}
            />
        </nav>
    );
};

export default CustomPagination;
