import { MouseEventHandler, useEffect, useMemo, useState } from 'react';
import { Dropdown as BootStrapDropdown } from 'bootstrap';
import _ from 'lodash';
import { TableColumn } from 'react-data-table-component/dist/src/DataTable/types';
import { extractTextContent, updateURLWithFiltersAndSorting } from 'Core-utils/helpers/helpers';
import { TableSubHeaderProps } from 'Core-utils/types/types';
import { SearchBar } from 'Components/molecules';
import { FilterDropdown } from 'Components/organisms';
import { Dispatch, RootState } from 'Rdx/store';
import { useDispatch, useSelector } from 'react-redux';
import { ALL, SEARCH_PLACEHOLDER } from 'Core-utils/constants/constants';

const getColumnNames = (columns: TableColumn<any>[]) => {
    return columns
        .map((column) => (column.name ? extractTextContent(column.name) || null : null))
        .filter((column) => column !== null);
};

export default function SubHeaderComponent<T>({
    onSearchChange,
    searchInputValue,
    subHeaderContent,
    subHeaderVariant,
    columns: columnsData,
    selectedOptions: filterOptions = {},
    filterColumns,
    dropdownOptions,
    setSelectedOptions: setFilterOptions,
    placeHolder,
}: TableSubHeaderProps<T>) {
    const dispatch = useDispatch<Dispatch>();
    const columns = getColumnNames(columnsData);
    const filterWithAction = subHeaderVariant === 'filter-with-actions' ? true : false;
    const [selectedOptions, setSelectedOptions] = useState<{
        [key: string]: string[];
    }>(filterOptions);
    const org = useSelector((state: RootState) => state.orgModel.org);

    const closeDropdown = () => {
        const dropdown = BootStrapDropdown.getOrCreateInstance(`#filter-dropdown`);
        dropdown.hide();
        dropdown.dispose();
    };

    const checkIsDisabled = (
        selectedOptions: { [key: string]: string[] },
        newSelectedOptions: { [key: string]: string[] },
    ) => {
        const selectedOptionsValues = Object.values(selectedOptions).flat().sort();
        const newSelectedOptionsValues = Object.values(newSelectedOptions).flat().sort();
        if (selectedOptionsValues.length !== newSelectedOptionsValues.length) {
            return false;
        }
        return selectedOptionsValues.every((val, index) => val === newSelectedOptionsValues[index]);
    };

    const isDisabled = useMemo(() => checkIsDisabled(filterOptions, selectedOptions), [filterOptions, selectedOptions]);

    const handleCheckboxChange = (option: string, isChecked: boolean, text: string) => {
        setSelectedOptions((prevSelectedOptions) => {
            const dropdownKey = text.toLowerCase();
            let newSelectedOptions;

            if (isChecked) {
                newSelectedOptions = {
                    ...prevSelectedOptions,
                    [dropdownKey]: [...(prevSelectedOptions[dropdownKey] || []), option],
                };
            } else {
                newSelectedOptions = {
                    ...prevSelectedOptions,
                    [dropdownKey]: prevSelectedOptions[dropdownKey].filter(
                        (selectedOption: string) => selectedOption !== option,
                    ),
                };
            }

            return newSelectedOptions;
        });
    };

    const handleClearClick = (dropdownKey: string): MouseEventHandler<HTMLButtonElement> => () => {
        setFilterOptions((prevSelectedOptions) => ({
            ...prevSelectedOptions,
            [dropdownKey.toLowerCase()]: [],
        }));
        setSelectedOptions((prevSelectedOptions) => ({
            ...prevSelectedOptions,
            [dropdownKey.toLowerCase()]: [],
        }));
        const updatedFilters = { ...selectedOptions };
        updatedFilters[dropdownKey.toLowerCase()] = [];
        updateURLWithFiltersAndSorting(updatedFilters);
        closeDropdown();
    };

    const handleApplyClick = () => {
        if (selectedOptions?.organization) {
            dispatch.orgModel.setOrg(ALL);
        }
        setFilterOptions(selectedOptions);
        updateURLWithFiltersAndSorting(selectedOptions);
        closeDropdown();
    };

    useEffect(() => {
        const updatedSelectedOptions = _.omit(selectedOptions, 'organization');
        if (org !== ALL) {
            setSelectedOptions(updatedSelectedOptions);
            setFilterOptions(updatedSelectedOptions);
            updateURLWithFiltersAndSorting(updatedSelectedOptions);
        }
    }, [org]);

    // TODO:[EZ-230] Handler functions needs to integrated with api
    const handleResetClick = () => {
        setFilterOptions({});
        setSelectedOptions({});
        updateURLWithFiltersAndSorting({});
    };
    const handleSaveClick = () => {};

    if (subHeaderVariant === 'filter-with-actions' || subHeaderVariant === 'filter-without-actions') {
        return (
            <div className="table-subheader d-flex gap-3 p-0" data-testid="filter-with-actions">
                <SearchBar value={searchInputValue} onChange={onSearchChange} placeholder={SEARCH_PLACEHOLDER} />
                <FilterDropdown
                    items={filterColumns ? filterColumns : columns}
                    options={dropdownOptions}
                    selectedOptions={selectedOptions}
                    handleCheckboxChange={handleCheckboxChange}
                    handleClearClick={handleClearClick}
                    handleResetClick={handleResetClick}
                    handleSaveClick={handleSaveClick}
                    handleApplyClick={handleApplyClick}
                    filterWithActions={filterWithAction}
                    closeDropdown={closeDropdown}
                    isDisabled={isDisabled}
                />
            </div>
        );
    } else if (subHeaderVariant === 'header-with-refresh-button') {
        return <div className="d-flex justify-content-between bg-red w-100">{subHeaderContent}</div>;
    } else {
        return (
            <div className="d-flex justify-content-between bg-red w-100">
                <SearchBar
                    value={searchInputValue}
                    onChange={onSearchChange}
                    placeholder={placeHolder ? placeHolder : SEARCH_PLACEHOLDER}
                />
                {subHeaderContent}
            </div>
        );
    }
}
