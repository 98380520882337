import { CgArrowsExpandRight } from '@react-icons/all-files/cg/CgArrowsExpandRight';
import { useState } from 'react';
import _ from 'lodash';
import { CustomCard } from 'Components/molecules';
import { HealthMetrics } from 'Components/molecules';
import { IconWithTypography } from 'Components/molecules';
import { HiOutlineTrendingUp } from '@react-icons/all-files/hi/HiOutlineTrendingUp';
import { HiOutlineTrendingDown } from '@react-icons/all-files/hi/HiOutlineTrendingDown';
import {
    filterMap,
    SECURITY_TRAINING_DEFICIENCIES,
    TrainingDeficiencyLabelMap,
    TRAINING_DEFICIENCIES_LABELS,
} from 'Core-utils/constants/constants';
const { REPORTS } = require('@ampsec/platform-client');
import { getMetricsReportsData } from 'Apis/library';
import { useApiObjectData } from 'Hooks/useApiObjectData';
import ErrorHandlerAndLoaderWrapper from 'Wrappers/ErrorHandlerandLoaderWrapper';
import { Color } from 'Core-utils/types/color';
import { FindingsGridModal } from 'Components/organisms';
import { TrendingMetric, TrendingMetricResults } from 'Core-utils/types/types';
import trainingIcon from 'Assets/icons/securityTraining.svg';
import { updateURLWithFiltersAndSorting } from 'Core-utils/helpers/helpers';
import './styles.scss';

const COMPONENT_IDENTIFIER = 'Organization Security Training Deficiencies';

const buildSecurityHealthMetric = (healthMetric: TrendingMetric, index: number) => {
    const isNegativeDelta = healthMetric?.delta?.toString().startsWith('-');
    const valueIcon = isNegativeDelta ? (
        <HiOutlineTrendingDown className="security-coverage-tooling__icon-color-red" />
    ) : (
        <HiOutlineTrendingUp className="security-coverage-tooling__icon-color-green" />
    );
    const labelColor: Color = 'primary-100';
    const label =
        healthMetric?.value === null
            ? '-'
            : index === 0 || index === 1
            ? healthMetric?.value.toString()
            : healthMetric?.value.toString() + ' Days';
    const value = (
        <IconWithTypography
            isRow={true}
            startIcon={valueIcon}
            label="10%"
            labelColor={isNegativeDelta ? 'secondary-accent-red-2' : 'success-green'}
        />
    );
    const header = (
        <IconWithTypography
            isRow={false}
            label={TrainingDeficiencyLabelMap[healthMetric?.label]}
            labelColor="text-medium-emphasis"
        />
    );
    return { header, label, labelColor, value };
};

const SecurityMetrics = (data: TrendingMetricResults) => {
    return (
        <div className="security-coverage-tooling__styled-container container">
            {TRAINING_DEFICIENCIES_LABELS.map((key, index) => {
                const metric = buildSecurityHealthMetric(data[key], index);
                return (
                    <div key={key} data-testid="security-testing-coverage">
                        {/* TODO: EZ-264 show value once we get that data */}
                        <HealthMetrics metric={metric} hideValue />
                    </div>
                );
            })}
        </div>
    );
};

const SecurityTrainingDeficiencies = () => {
    const [show, setShow] = useState(false);
    const { data, error, isLoading, refresh } = useApiObjectData<TrendingMetricResults, TrendingMetricResults>(
        REPORTS.TRAINING_DEFICIENCIES,
        getMetricsReportsData,
        (dataItem: TrendingMetricResults) => {
            return _.pick(dataItem, TRAINING_DEFICIENCIES_LABELS);
        },
        { department: 'ALL' },
    );

    const handleClose = () => {
        updateURLWithFiltersAndSorting({});
        setShow(false);
    };
    const handleClick = () => {
        setShow(!show);
    };

    return (
        <div className="security-coverage-tooling h-100" data-testid="security-testid">
            <CustomCard
                cardType="oneThird"
                title={
                    <>
                        <IconWithTypography isRow={true} label={SECURITY_TRAINING_DEFICIENCIES} labelColor="primary" />
                        <CgArrowsExpandRight
                            size={22}
                            className="security-coverage-tooling__icon"
                            onClick={handleClick}
                            data-testid="expand-icon"
                        />
                    </>
                }
                content={
                    <div className="security-coverage-tooling__error-container">
                        <ErrorHandlerAndLoaderWrapper
                            error={error}
                            isLoading={isLoading}
                            render={() => SecurityMetrics(data)}
                            handleRefresh={refresh}
                            isEmpty={_.isEmpty(data)}
                            ImageSrc={trainingIcon}
                            component={COMPONENT_IDENTIFIER}
                        />
                    </div>
                }
                onCardClick={handleClick}
            />
            <FindingsGridModal
                title={SECURITY_TRAINING_DEFICIENCIES}
                onClose={handleClose}
                show={show}
                defaultFilter={filterMap['Training']}
            />
        </div>
    );
};

export default SecurityTrainingDeficiencies;
