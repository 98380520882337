import { MdOutlineContentCopy } from '@react-icons/all-files/md/MdOutlineContentCopy';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import type { Document as ContentfulDocument } from '@contentful/rich-text-types';
import { BLOCKS } from '@contentful/rich-text-types';
import { Accordion } from 'Components/atoms';
import { Typography } from 'Components/atoms';
import { BackButton } from 'Components/molecules';
import {
    CUSTOM_FINDINGS,
    FINDINGS_CONFIGURATION,
    INSTRUCTIONS,
    WEBHOOK_CONFIG_DATA,
    WEBHOOK_CREDENTIALS,
} from 'Core-utils/constants/constants';
import { POLICIES_ROUTE } from 'Core-utils/routes';
import { InputField } from 'Components/molecules';
import { FindingsConfigTable } from 'Components/organisms/FindingsConfigTable';
import { useRichI18n } from 'Hooks/useRichI18n';
import { KIND, TokenDto } from '@ampsec/platform-client';
import { getWebhookURL } from 'Apis/library';
import { useApiObjectData } from 'Hooks/useApiObjectData';
import { AccordionItemProps } from 'Components/atoms/Accordion/Accordion';
import './styles.scss';

const CustomFindingsPage = () => {
    const { data: fetchWebhookCredentials } = useApiObjectData<TokenDto[], TokenDto[]>(
        KIND.TOKENS,
        getWebhookURL,
        (a) => a,
    );
    const { t } = useRichI18n();

    const handleCopy = (index: number) => {
        const copyText = document.getElementById(`inputField-${index}`) as HTMLInputElement;
        if (copyText) {
            copyText.select();
            navigator.clipboard.writeText(copyText.value);
        }
    };

    const instructionsChildren =
        typeof t('WEBHOOK_INSTRUCTIONS') === 'string' ? (
            <Typography variant="subtitle3">{t('WEBHOOK_INSTRUCTIONS')}</Typography>
        ) : (
            <div style={{ color: 'var(--bs-primary-100)' }}>
                {documentToReactComponents(((t('WEBHOOK_INSTRUCTIONS') as unknown) as ContentfulDocument) || null, {
                    renderNode: {
                        [BLOCKS.EMBEDDED_ASSET]: (node, _children) => {
                            return (
                                <img
                                    src={`https://${node.data.target.fields.file.url}`}
                                    height={node.data.target.fields.file.details.image.height}
                                    width={node.data.target.fields.file.details.image.width}
                                    alt={node.data.target.fields.description}
                                />
                            );
                        },
                    },
                })}
            </div>
        );
    const accordionItems: AccordionItemProps[] = [
        {
            key: '0',
            title: INSTRUCTIONS,
            isConfigurationRequired: false,
            eventKey: '0',
            children: instructionsChildren,
        },
        {
            key: '1',
            title: WEBHOOK_CREDENTIALS,
            isConfigurationRequired: false,
            eventKey: '1',
            children: (
                <div className="d-flex flex-column gap-3 w-50">
                    {WEBHOOK_CONFIG_DATA.map((configItem, index) => (
                        <>
                            <div className="d-flex gap-2 align-items-center">
                                <InputField
                                    key={index}
                                    label={configItem.label}
                                    placeholder={configItem.placeholder}
                                    value={fetchWebhookCredentials[0]?.links?.webhook}
                                    variant={configItem.variant}
                                    id={`inputField-${index}`}
                                />
                                <MdOutlineContentCopy
                                    className="text-text-low-emphasis mt-4"
                                    cursor={'pointer'}
                                    size={20}
                                    onClick={() => handleCopy(index)}
                                />
                            </div>
                        </>
                    ))}
                </div>
            ),
        },
        {
            key: '2',
            title: FINDINGS_CONFIGURATION,
            isConfigurationRequired: false,
            eventKey: '2',
            children: <FindingsConfigTable />,
        },
    ];
    return (
        <div className="custom-findings d-flex flex-column gap-2">
            <BackButton navigateBack={POLICIES_ROUTE} />
            <div className="custom-findings__content d-flex flex-column bg-text-black gap-3 rounded p-3 custom-scrollbar">
                <Typography variant="body12" color="text-high-emphasis" style={{ marginBottom: '0%' }}>
                    {CUSTOM_FINDINGS}
                </Typography>
                <Accordion accordionItems={accordionItems} alwaysOpen={true} defaultActiveKey={['0']} />
            </div>
        </div>
    );
};

export default CustomFindingsPage;
