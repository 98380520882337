export const SIGN_IN = '/signin';
export const DASHBOARD_ROUTE = '/dashboard/*';
export const USER_SPECIFIC_ROUTE = '/dashboard/users/:id';
export const HOME_ROUTE = '/dashboard';
export const FINDINGS_ROUTE = '/findings';
export const WORKFLOW_ROUTE = '/workflow/*';
export const WORKFLOW_SPECIFIC_ROUTE = '/workflow/active-flows/:id';
export const POLICIES_ROUTE = '/policies';
export const DEFAULT_WORKFLOW_ROUTE = '/workflow';
export const ORG_DASHBOARD_ROUTE = `${HOME_ROUTE}/org`;
export const DEPT_DASHBOARD_ROUTE = `${HOME_ROUTE}/dept`;
export const USERS_DASHBOARD_ROUTE = `${HOME_ROUTE}/users`;
export const ACTIVE_WORKFLOWS_ROUTE = `${DEFAULT_WORKFLOW_ROUTE}/active-flows`;
export const ACTIONS_WORKFLOWS_ROUTE = `${DEFAULT_WORKFLOW_ROUTE}/actions`;
export const DEFAULT_ROUTE = '/';
export const NOT_FOUND_ROUTE = '*';
export const ORG_ROUTE = 'org';
export const DEPT_ROUTE = 'dept';
export const USERS_ROUTE = 'users';
export const ACTIVE_FLOWS_ROUTE = 'active-flows';
export const ACTIONS_ROUTE = 'actions';
export const PAGE_ROUTE = '/*';
export const SETTINGS_SPECIFIC_ROUTE = '/settings';
export const TOOLING_COVERAGE_ROUTE = '/coverage';
export const REDIRECT_ROUTE = '/auth/redirect';
export const INTEGRATIONS_ROUTE = '/policies/:cid';
export const TENANTS_ROUTE = '/auth/tenants';
export const CREATE_ENGAGEMENT_ROUTE = `${DEFAULT_WORKFLOW_ROUTE}/create-engagement/:id`;
export const CUSTOM_FINDINGS_ROUTE = '/policies/custom-findings';
export const CUSTOM_ACTIONS_DETAIL_ROUTE = `${DEFAULT_WORKFLOW_ROUTE}/actions/:id`;
