import {
    SECURITY_HEALTH_LABEL,
    SECURITY_HEALTH,
    USER_SECURITY_HEALTH,
    ORGANIZATION,
    DEPARTMENT,
} from 'Core-utils/constants/constants';
import React from 'react';
import { UserLineGraphCard } from 'Components/organisms';
import { UserSecurityHealthCard } from 'Components/organisms';
import { UserTopRiskContributors } from 'Components/organisms';
import { getMetricsReportsData } from 'Apis/library';
import { toLineGraphData, toRiskContributorsData } from 'Core-utils/helpers/helpers';
import { BaseMetricResults, TrendingMetricResults, UserDetailContentType } from 'Core-utils/types/types';
import { useApiObjectData } from 'Hooks/useApiObjectData';
import { useSelector } from 'react-redux';
import { RootState } from 'Rdx/store';
import { REPORTS } from '@ampsec/platform-client';
import _ from 'lodash';
import HealthTrend from 'Assets/icons/healthTrend.svg';
import RiskContributorsIcon from 'Assets/icons/riskContributors.svg';

type SecurityHealthCardGroupProps = {
    disableDepartment?: boolean;
    department: string;
} & (
    | {
          dashboardVariant: 'department';
      }
    | {
          dashboardVariant: 'userDetails';
          userId: string;
          userData: UserDetailContentType;
      }
);

const SecurityHealthCardGroup: React.FC<SecurityHealthCardGroupProps> = (props) => {
    const org = useSelector((state: RootState) => state.orgModel.org);
    const orgFilter = props.dashboardVariant === 'userDetails' ? props.userData.organization : org;
    const userIdFilter = props.dashboardVariant === 'userDetails' ? props.userId : '';
    const departmentFilter = props.dashboardVariant === 'department' ? props.department : props.userData.department;
    const title = props.dashboardVariant === 'department' ? SECURITY_HEALTH : USER_SECURITY_HEALTH;

    const {
        data: userLineGraphData,
        error: userLineGraphDataError,
        isLoading: userLineGraphDataIsLoading,
        refresh: userLineGraphDataRefresh,
    } = useApiObjectData<BaseMetricResults, BaseMetricResults>(
        REPORTS.HEALTH_SCORE_TREND,
        getMetricsReportsData,
        (dataItem: BaseMetricResults) => {
            return dataItem;
        },
        { organization: orgFilter, uid: userIdFilter },
    );

    const {
        data: orgLineGraphData,
        error: orgLineGraphDataError,
        isLoading: orgLineGraphDataIsLoading,
        refresh: orgLineGraphDataRefresh,
    } = useApiObjectData<BaseMetricResults, BaseMetricResults>(
        REPORTS.HEALTH_SCORE_TREND,
        getMetricsReportsData,
        (a) => a,
        { organization: orgFilter, department: 'ALL' },
    );

    const {
        data: deptLineGraphData,
        error: deptLineGraphDataError,
        isLoading: deptLineGraphDataIsLoading,
        refresh: deptLineGraphDataRefresh,
    } = useApiObjectData<BaseMetricResults, BaseMetricResults>(
        REPORTS.HEALTH_SCORE_TREND,
        getMetricsReportsData,
        (dataItem: BaseMetricResults) => {
            return dataItem;
        },
        { organization: orgFilter, department: departmentFilter },
    );

    const {
        data: userRiskContributorsData,
        error: userRiskContributorsDataError,
        isLoading: userRiskContributorsDataIsLoading,
        refresh: userRiskContributorsDataRefresh,
    } = useApiObjectData<BaseMetricResults, BaseMetricResults>(
        REPORTS.TOP_RISK_CONTRIBUTORS,
        getMetricsReportsData,
        (a) => a,
        { organization: orgFilter, uid: userIdFilter },
    );

    const {
        data: orgRiskContributorsData,
        error: orgRiskContributorsDataError,
        isLoading: orgRiskContributorsDataIsLoading,
        refresh: orgRiskContributorsDataRefresh,
    } = useApiObjectData<BaseMetricResults, BaseMetricResults>(
        REPORTS.TOP_RISK_CONTRIBUTORS,
        getMetricsReportsData,
        (a) => a,
        { organization: orgFilter, department: 'ALL' },
    );

    const {
        data: deptRiskContributorsData,
        error: deptRiskContributorsDataError,
        isLoading: deptRiskContributorsDataIsLoading,
        refresh: deptRiskContributorsDataRefresh,
    } = useApiObjectData<BaseMetricResults, BaseMetricResults>(
        REPORTS.TOP_RISK_CONTRIBUTORS,
        getMetricsReportsData,
        (a) => a,
        {
            organization: orgFilter,
            department: departmentFilter,
        },
    );

    const { data: deptData } = useApiObjectData<TrendingMetricResults, TrendingMetricResults>(
        REPORTS.HEALTH_SCORE,
        getMetricsReportsData,
        (a) => a,
        { organization: org, department: props.department },
    );

    const seriesUserLineGraphData = toLineGraphData(userLineGraphData);
    const seriesOrgLineGraphData = toLineGraphData(orgLineGraphData);
    const seriesDeptLineGraphData = toLineGraphData(deptLineGraphData);
    const seriesUserRiskContributorsData = toRiskContributorsData(userRiskContributorsData);
    const seriesOrgRiskContributorsData = toRiskContributorsData(orgRiskContributorsData);
    const seriesDeptRiskContributorsData = toRiskContributorsData(deptRiskContributorsData);
    const name =
        props.dashboardVariant === 'userDetails' ? props.userData.firstName + ' ' + props.userData.lastName : '';

    const userSeriesData = [
        {
            name: name,
            data: seriesUserLineGraphData,
        },

        {
            name: ORGANIZATION,
            data: seriesOrgLineGraphData,
        },
        {
            name: DEPARTMENT,
            data: seriesDeptLineGraphData,
        },
    ];
    const userDatasets = [
        {
            data: seriesUserRiskContributorsData,
            backgroundColor: 'rgba(255,255,255, 0.2)',
            borderColor: '#FFFFFF',
            name,
        },
        {
            data: seriesOrgRiskContributorsData,
            backgroundColor: 'rgba(153, 117, 255, 0.2)',
            borderColor: '#9975ff',
            name: ORGANIZATION,
        },
        {
            data: seriesDeptRiskContributorsData,
            backgroundColor: 'rgba(149, 143, 163, 0.2)',
            borderColor: '#958fa3',
            name: DEPARTMENT,
        },
    ];

    const departmentSeriesData = [
        {
            name: DEPARTMENT,
            data: seriesDeptLineGraphData,
        },
        {
            name: ORGANIZATION,
            data: seriesOrgLineGraphData,
        },
    ];
    const departmentDatasets = [
        {
            data: seriesDeptRiskContributorsData,
            backgroundColor: 'rgba(255,255,255, 0.2)',
            borderColor: '#FFFFFF',
            name: DEPARTMENT,
        },
        {
            data: seriesOrgRiskContributorsData,
            backgroundColor: 'rgba(153, 117, 255, 0.2)',
            borderColor: '#9975ff',
            name: ORGANIZATION,
        },
    ];

    const isEmptyLineGraphData =
        props.dashboardVariant === 'department'
            ? deptLineGraphData || orgLineGraphData
            : userLineGraphData || deptLineGraphData || orgLineGraphData;
    const isEmptyRiskContributorsData =
        props.dashboardVariant === 'department'
            ? deptRiskContributorsData || orgRiskContributorsData
            : userRiskContributorsData || deptRiskContributorsData || orgRiskContributorsData;
    const seriesData = props.dashboardVariant === 'department' ? departmentSeriesData : userSeriesData;
    const datasets = props.dashboardVariant === 'department' ? departmentDatasets : userDatasets;
    const criticalRiskScore =
        props.dashboardVariant === 'department'
            ? deptData?.[SECURITY_HEALTH_LABEL]?.value
            : parseFloat(props.userData.score);
    const lineGraphErrorHandler =
        props.dashboardVariant === 'department'
            ? deptLineGraphDataError || orgLineGraphDataError
            : userLineGraphDataError || deptLineGraphDataError || orgLineGraphDataError;
    const lineGraphIsLoadingHandler =
        props.dashboardVariant === 'department'
            ? deptLineGraphDataIsLoading || orgLineGraphDataIsLoading
            : userLineGraphDataIsLoading || deptLineGraphDataIsLoading || orgLineGraphDataIsLoading;
    const lineGraphRefreshHandler =
        props.dashboardVariant === 'department'
            ? deptLineGraphDataRefresh || orgLineGraphDataRefresh
            : userLineGraphDataRefresh || deptLineGraphDataRefresh || orgLineGraphDataRefresh;
    const riskContributorsErrorHandler =
        props.department === 'department'
            ? orgRiskContributorsDataError || deptRiskContributorsDataError
            : userRiskContributorsDataError || orgRiskContributorsDataError || deptRiskContributorsDataError;
    const riskContributorsIsLoadingHandler =
        props.dashboardVariant === 'department'
            ? orgRiskContributorsDataIsLoading || deptRiskContributorsDataIsLoading
            : userRiskContributorsDataIsLoading ||
              orgRiskContributorsDataIsLoading ||
              deptRiskContributorsDataIsLoading;
    const riskContributorsRefreshHandler =
        props.dashboardVariant === 'department'
            ? orgRiskContributorsDataRefresh || deptRiskContributorsDataRefresh
            : userRiskContributorsDataRefresh || orgRiskContributorsDataRefresh || deptRiskContributorsDataRefresh;
    return (
        <div className="d-flex gap-3 w-100">
            <UserSecurityHealthCard
                criticalRisk={criticalRiskScore}
                organization={orgFilter}
                title={title}
                department={props.dashboardVariant === 'userDetails' ? props.userData.department : undefined}
                disableDepartment={props.disableDepartment}
            />
            <UserLineGraphCard
                series={seriesData}
                error={lineGraphErrorHandler}
                isLoading={lineGraphIsLoadingHandler}
                onRefresh={lineGraphRefreshHandler}
                isEmpty={_.isEmpty(isEmptyLineGraphData)}
                imageSrc={HealthTrend}
            />
            <UserTopRiskContributors
                datasets={datasets}
                error={riskContributorsErrorHandler}
                isLoading={riskContributorsIsLoadingHandler}
                onRefresh={riskContributorsRefreshHandler}
                isEmpty={_.isEmpty(isEmptyRiskContributorsData)}
                imageSrc={RiskContributorsIcon}
            />
        </div>
    );
};

export default SecurityHealthCardGroup;
