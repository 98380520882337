import numeral from 'numeral';
import _ from 'lodash';
import 'Theme/variables.scss';
import {
    ACCEPT_FINDINGS_SUCCESS_MSG,
    ACCEPT_FINDINGS_VARIANT,
    DEFAULT_ERROR_MSG,
    ENGAGE_SUCCESS_MSG,
    ENGAGE_VARIANT,
    ERROR_VARIANT,
    FALSE_POSITIVE_SUCCESS_MSG,
    FALSE_POSITIVE_VARIANT,
    OPEN_SUCCESS_MSG,
    OPEN_VARIANT,
    TOP_RISK_CONTRIBUTORS_MFA_LABEL,
    TOP_RISK_CONTRIBUTORS_TOOLING_LABEL,
    TOP_RISK_CONTRIBUTORS_TRAINING_LABEL,
    TOP_RISK_CONTRIBUTORS_VULN_LABEL,
    USER_SCORE_THRESHOLD_VALUES,
} from '../constants/constants';
import {
    ThresholdMapping,
    ChipProps,
    BaseMetricResults,
    TrendingMetricResults,
    DataProp,
    AmpSentryTags,
} from '../types/types';
import {
    ConnectorUpsertDto,
    ContentStrategyKind,
    CreateNotificationDto,
    DeliveryStrategyKind,
    FindingDto,
    ProviderDto,
    FindingStatus,
    FindingsInsightsDto,
    MfaRating,
    _MfaRating,
    UserCoverageSummary,
    ServiceProfile,
    CredentialStatus,
    MfaStatus,
    AMP_SETTINGS,
    AmpSettingsMap,
    TypedAmpSettingsKey,
} from '@ampsec/platform-client';
import classNames from 'classnames';
import moment from 'moment';
import React from 'react';
import queryString from 'query-string';
import { IoCallOutline } from '@react-icons/all-files/io5/IoCallOutline';
import { MdOutlineEmail } from '@react-icons/all-files/md/MdOutlineEmail';
import { VscBellDot } from '@react-icons/all-files/vsc/VscBellDot';
import { LuShieldQuestion } from '@react-icons/all-files/lu/LuShieldQuestion';
import { MdOutlineTextsms } from '@react-icons/all-files/md/MdOutlineTextsms';
import { MdOutlineUsb } from '@react-icons/all-files/md/MdOutlineUsb';
import { MdOutlinePassword } from '@react-icons/all-files/md/MdOutlinePassword';
import { BsGlobe } from '@react-icons/all-files/bs/BsGlobe';
import { BsShieldLock } from '@react-icons/all-files/bs/BsShieldLock';
import { BiEditAlt } from '@react-icons/all-files/bi/BiEditAlt';
import { Employee } from 'Components/organisms/Users/usersTable';
import { useI18n } from 'Hooks/useI18n';
import { AmpSentryEvent } from 'Core-utils/types/SentryTypes';
import { useSelector } from 'react-redux';
import { RootState } from 'Rdx/store';

export type SvgReact = React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
    }
>;

export const mapThreshold = (value: number): ThresholdMapping => {
    if (value <= 70) {
        return { label: 'Critical Risk', color: 'secondary-accent-red-2' };
    } else if (value <= 80) {
        return { label: 'High Risk', color: 'secondary-accent-orange-1' };
    } else if (value <= 90) {
        return { label: 'Medium Risk', color: 'secondary-accent-yellow-1' };
    } else {
        return { label: 'Low Risk', color: 'success-green' };
    }
};

export const extractDomain = (url: string) => {
    if (!url.startsWith('http')) {
        return null;
    }
    const updatedUrl = url.replace(/\[.*?\]/g, 'www');
    const hostname = new URL(updatedUrl).hostname;
    const hostnameParts = hostname.split('.').slice(1).join('.');
    return hostnameParts;
};

export const dateCalculation = (startDate: Date, currentDate: Date, dateType: 'short' | 'full' = 'full') => {
    let months = 0;
    months = (currentDate.getFullYear() - startDate.getFullYear()) * 12;
    months -= startDate.getMonth();
    months += currentDate.getMonth();
    months = months <= 0 ? 0 : months;

    if (dateType === 'short') {
        return months > 11 ? `${Math.floor(months / 12)}y` : `${months}m`;
    }

    const years = Math.floor(months / 12);

    if (years === 1) {
        return `${years} Year`;
    } else if (years > 1) {
        return `${years} Years`;
    } else {
        if (months > 1) {
            return `${months} Months`;
        } else {
            return `${months} Month`;
        }
    }
};

export const concatName = (firstName: string, lastName: string) => {
    return firstName + ' ' + lastName;
};

export const getColor = (score?: number) => {
    const scoreNumber = Number(score);
    if (scoreNumber <= USER_SCORE_THRESHOLD_VALUES.CRITICAL) {
        return '#DC3B3B';
    } else if (scoreNumber <= USER_SCORE_THRESHOLD_VALUES.HIGH) {
        return '#FF912C';
    } else if (scoreNumber <= USER_SCORE_THRESHOLD_VALUES.MEDIUM) {
        return '#F1DF3A';
    } else {
        return '#A1D730';
    }
};

export const chipRiskClassMap = (score: number) => {
    const variantClassName = classNames('d-flex', 'align-items-center', 'justify-content-center', {
        'chip__user-score-critical': score <= USER_SCORE_THRESHOLD_VALUES.CRITICAL,
        'chip__user-score-high':
            score > USER_SCORE_THRESHOLD_VALUES.CRITICAL && score <= USER_SCORE_THRESHOLD_VALUES.HIGH,
        'chip__user-score-medium':
            score > USER_SCORE_THRESHOLD_VALUES.HIGH && score <= USER_SCORE_THRESHOLD_VALUES.MEDIUM,
        'chip__user-score-low-risk': score > USER_SCORE_THRESHOLD_VALUES.MEDIUM,
        body2: true,
    });
    return variantClassName;
};

export const chipStatusClassMap = (status: string) => {
    const variantClassName = classNames('d-flex', 'align-items-center', 'justify-content-center', 'chip__status', {
        'chip__status-active': status === 'ACTIVE',
        'chip__status-paused': status === 'PAUSED',
        'chip__status-ghost': status === 'GHOST',
        'chip__status-invited': status === 'INVITED',
        'chip__status-suspended': status === 'SUSPENDED',
        'chip__status-error': status === 'ERROR',
    });

    return variantClassName;
};

export const severityMap = (severity: string) => {
    return classNames('d-flex', 'align-items-center', 'justify-content-center', 'text-uppercase caption4', {
        'chip__severity-critical': severity === 'CRITICAL',
        'chip__severity-high': severity === 'HIGH',
        'chip__severity-medium': severity === 'MEDIUM',
        'chip__severity-low': severity === 'LOW',
    });
};

const DATETIME_FORMAT = 'MM-DD-YYYY, HH:mm A';

export const DateTimeFormatter = (dateString: string): string => {
    if (!dateString) {
        return '';
    }
    return moment(dateString).format(DATETIME_FORMAT);
};

export const severityMapForResolvedIssue = (severity: string) => {
    const variantClassName = classNames('d-flex', 'align-items-center', 'justify-content-center', 'text-uppercase', {
        'chip__severity-resolved-critical':
            severity === 'CRITICAL' || severity === 'CRITICAL RISK' || severity === CredentialStatus.NOT_INSTALLED,
        'chip__severity-resolved-high':
            severity === 'HIGH' || severity === 'HIGH RISK' || severity === CredentialStatus.INVALID,
        'chip__severity-resolved-medium': severity === 'MEDIUM' || severity === 'MEDIUM RISK',
        'chip__severity-resolved-low':
            severity === 'LOW' || severity === 'LOW RISK' || severity === CredentialStatus.VALID,
        'chip__severity-resolved-info': severity === 'INFO' || severity === 'INFO RISK',
        caption4: true,
    });

    return variantClassName;
};

export const getColumnValues = <T, K extends keyof T>(data: T[], key: K): T[K][] => {
    const response = data?.map((item) => item[key]);
    return _.uniq(response);
};

export const getTotalArrayLength = (selectedOptions: { [key: string]: string[] }) => {
    const length: number = _.reduce(
        selectedOptions,
        (sum: number, valueArray: string[]) => {
            const valueArrayLength = valueArray.length;
            return sum + valueArrayLength;
        },
        0,
    );
    return length;
};

export const opacityClassName = (row: FindingDto) =>
    classNames({
        'opacity-transparent': row.status !== FindingStatus.OPEN && row.status !== FindingStatus.ACCEPTED_RISK,
        'opacity-1': row.status === FindingStatus.OPEN || row.status === FindingStatus.ACCEPTED_RISK,
    });

export const opacityViewClassName = (row: FindingsInsightsDto) =>
    classNames({
        'opacity-transparent': row.status !== FindingStatus.OPEN && row.status !== FindingStatus.ACCEPTED_RISK,
        'opacity-1': row.status === FindingStatus.OPEN || row.status === FindingStatus.ACCEPTED_RISK,
    });

export const activeTabClassName = (isTrue: boolean) => classNames({ 'active-tab': isTrue });

export const getInitials = (name: string, height?: string, width?: string) => {
    const names = name?.split(' ');
    let initials = '';

    if (height && width && parseInt(height) > 24 && parseInt(width) > 24) {
        if (names.length > 0) {
            initials += names[0].charAt(0);
        }

        if (names.length > 1) {
            initials += names[names.length - 1].charAt(0);
        }
    } else {
        initials += name?.charAt(0);
    }

    return initials.toUpperCase();
};

export const getVariantClassName = (variant: ChipProps['variant'], value: string) => {
    switch (variant) {
        case 'user_score': {
            const score = parseInt(value);
            return chipRiskClassMap(score);
        }
        case 'top_risk_issue':
            return 'align-items-center justify-content-center chip__top-risk-issue-variant overline1';
        case 'severity':
            return severityMap(value);
        case 'employee_risk_variant':
            return 'd-flex align-items-center justify-content-center chip__employee-risk body2';
        case 'status':
            return chipStatusClassMap(value);
        default:
            return '';
    }
};
export const formatValue = (_label: string, value: number): string => {
    // Commenting for now, might be needed in the future
    // Format Time
    // if (label === ENGAGEMENT_VALUE_LABELS[2]) {
    //     return `${numeral(value).format('0.0a')} hrs`;
    // }
    //
    // // Format Dollars
    // if (label === ENGAGEMENT_VALUE_LABELS[3]) {
    //     return numeral(value).format('$0a');
    // }

    // Format other values
    return value <= 1000 ? value.toString() : numeral(value).format('0.0a');
};

export const calculateNeedleRotation = (value: number) => {
    const inputMin = 0;
    const inputMax = 100;
    const rotationMin = -70.6;
    const rotationMax = 23.8;

    let rotation = ((value - inputMin) / (inputMax - inputMin)) * (rotationMax - rotationMin) + rotationMin;

    switch (true) {
        case value >= 0 && value <= 10:
            const scaledSpeed1 = 23.5 * ((value - inputMin) / (inputMax - inputMin));
            rotation = rotationMin + scaledSpeed1;
            break;
        case value > 10 && value <= 20:
            const scaledSpeed2 = 29.5 * ((value - inputMin) / (inputMax - inputMin));
            rotation = rotationMin + scaledSpeed2;
            break;
        case value > 20 && value <= 30:
            const scaledSpeed3 = 35.5 * ((value - inputMin) / (inputMax - inputMin));
            rotation = rotationMin + scaledSpeed3;
            break;
        case value > 30 && value <= 40:
            const scaledSpeed4 = 38.5 * ((value - inputMin) / (inputMax - inputMin));
            rotation = rotationMin + scaledSpeed4;
            break;
        case value > 40 && value <= 50:
            const scaledSpeed5 = 45.5 * ((value - inputMin) / (inputMax - inputMin));
            rotation = rotationMin + scaledSpeed5;
            break;
        case value > 50 && value <= 55:
            const scaledSpeed6 = 50.5 * ((value - inputMin) / (inputMax - inputMin));
            rotation = rotationMin + scaledSpeed6;
            break;
        case value > 55 && value <= 60:
            const scaledSpeed7 = 53.5 * ((value - inputMin) / (inputMax - inputMin));
            rotation = rotationMin + scaledSpeed7;
            break;
        case value > 60 && value <= 65:
            const scaledSpeed8 = 58.5 * ((value - inputMin) / (inputMax - inputMin));
            rotation = rotationMin + scaledSpeed8;
            break;
        case value > 65 && value <= 70:
            const scaledSpeed9 = 62.5 * ((value - inputMin) / (inputMax - inputMin));
            rotation = rotationMin + scaledSpeed9;
            break;
        case value > 70 && value <= 75:
            const scaledSpeed10 = 67.5 * ((value - inputMin) / (inputMax - inputMin));
            rotation = rotationMin + scaledSpeed10;
            break;
        case value > 75 && value <= 80:
            const scaledSpeed11 = 72.5 * ((value - inputMin) / (inputMax - inputMin));
            rotation = rotationMin + scaledSpeed11;
            break;
        case value > 80 && value <= 85:
            const scaledSpeed12 = 76.5 * ((value - inputMin) / (inputMax - inputMin));
            rotation = rotationMin + scaledSpeed12;
            break;
        case value > 85 && value <= 90:
            const scaledSpeed13 = 82.5 * ((value - inputMin) / (inputMax - inputMin));
            rotation = rotationMin + scaledSpeed13;
            break;
        case value > 90 && value <= 95:
            const scaledSpeed14 = 87.5 * ((value - inputMin) / (inputMax - inputMin));
            rotation = rotationMin + scaledSpeed14;
            break;
        case value > 95 && value <= 100:
            const scaledSpeed15 = 93.5 * ((value - inputMin) / (inputMax - inputMin));
            rotation = rotationMin + scaledSpeed15;
            break;
    }

    rotation = Math.max(rotationMin, Math.min(rotation, rotationMax));

    return rotation;
};

export const calculateUserMetricsPosition = (value: number) => {
    let cx = 0;
    let cy = 0;

    switch (true) {
        case value >= 0 && value <= 6:
            cx = 29.5;
            cy = 97.5;
            break;
        case value > 6 && value <= 10:
            cx = 32.5;
            cy = 96.5;
            break;
        case value > 10 && value <= 14:
            cx = 32.5 + (value - 10);
            cy = 96.5 - (value - 10);
            break;
        case value > 14 && value <= 19:
            cx = 36.5 + (value - 15);
            cy = 92.5 - (value - 15);
            break;
        case value > 19 && value <= 25:
            cx = 40.5 + (value - 20);
            cy = 89.5 - (value - 20);
            break;
        case value > 25 && value <= 29:
            cx = 45.5 + (value - 25);
            cy = 85.5 - (value - 25);
            break;
        case value > 29 && value <= 34:
            cx = 53.5 + (value - 30);
            cy = 79.5 - (value - 30);
            break;
        case value > 34 && value <= 39:
            cx = 61.5 + (value - 35);
            cy = 74.5 - (value - 35);
            break;
        case value > 39 && value <= 44:
            cx = 71.5 + (value - 40) * 2;
            cy = 67.5 - (value - 40);
            break;
        case value > 44 && value <= 49:
            cx = 84.5 + (value - 45) * 2;
            cy = 60.5 - (value - 45);
            break;
        case value > 49 && value <= 54:
            cx = 97.5 + (value - 50) * 2;
            cy = 54.5 - (value - 50);
            break;
        case value > 54 && value <= 59:
            cx = 113.5 + (value - 55) * 2;
            cy = 48.5 - (value - 55);
            break;
        case value > 59 && value <= 64:
            cx = 131.5 + (value - 60) * 4;
            cy = 43.5 - (value - 60);
            break;
        case value > 64 && value <= 69:
            cx = 152.5 + (value - 65) * 4;
            cy = 39.5 - (value - 65);
            break;
        case value > 69 && value <= 74:
            cx = 175.5 + (value - 70) * 4;
            cy = 35.5;
            break;
        case value > 74 && value <= 79:
            cx = 200.5 + (value - 75) * 4;
            cy = 36.5;
            break;
        case value > 79 && value <= 84:
            cx = 226.5 + (value - 80) * 4;
            cy = 39.5 + (value - 80);
            break;
        case value > 84 && value <= 89:
            cx = 255.5 + (value - 85) * 5;
            cy = 48.5 + (value - 85) * 2;
            break;
        case value > 89 && value <= 94:
            cx = 286.5 + (value - 90) * 5;
            cy = 60.5 + (value - 90) * 3;
            break;
        case value > 94 && value <= 100:
            cx = 315.5 + (value - 95) * 5;
            cy = 77.5 - (value - 95) * -4;
            break;
        case value > 100:
            cx = 340.5;
            cy = 97.5;
            break;
    }

    return { cx, cy };
};

// Ensure the value is rounded to the nearest integer and between 0 and 100 using Math.min and Math.max
export const roundOffValue = (value: number = 0) => {
    return Math.min(Math.max(Math.round(value), 0), 100);
};

export const isValidEmail = (emailInput: string) => {
    const emailList = emailInput.split(',');
    const regex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    return emailList.every((email) => regex.test(email.trim()));
};

export const escapeRegexCharacters = (query: string) => {
    return query.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

export const clearSWRCache = (cache: any) => {
    const cacheKeysIterator = cache.keys();
    for (const key of cacheKeysIterator) {
        cache.delete(key);
    }
};

export const extractTextContent = (element: React.ReactNode): string | null => {
    if (typeof element === 'string') {
        return element;
    }

    if (React.isValidElement(element)) {
        const children = React.Children.toArray(element.props.children);
        if (children.length > 0) {
            return extractTextContent(children[0]);
        }
    }

    return null;
};

export const extractQueryParameters = () => {
    const keyValuePairs: Record<string, string[]> = {};
    const params = new URLSearchParams(location.search);
    for (const [key, value] of params) {
        if (Array.isArray(keyValuePairs[key])) {
            keyValuePairs[key].push(value);
        } else {
            keyValuePairs[key] = [value];
        }
    }
    return keyValuePairs;
};

export const updateURLWithFiltersAndSorting = (
    filters: Object | null = null,
    sortColumn: string | null = null,
    sortDirection: string | null = null,
) => {
    const queryParameters: Record<string, any> = {};

    if (filters !== null) {
        Object.assign(queryParameters, filters);
    }

    if (sortColumn !== null && sortDirection !== null) {
        queryParameters.sortColumn = sortColumn;
        queryParameters.sortDirection = sortDirection;
    }

    const query = queryString.stringify(queryParameters);
    const newURL = `${window.location.pathname}?${query}`;
    window.history.pushState({}, '', newURL);
};

export const convertFindingToNotificationData = (
    finding: FindingDto,
    text: string,
    copySecOpsTeam: boolean,
    deliveryStrategyKind: DeliveryStrategyKind,
): CreateNotificationDto => {
    return {
        channel: deliveryStrategyKind,
        content: {
            kind: ContentStrategyKind.RAW,
            content: text,
        },
        options: {
            copySecOpsTeam: copySecOpsTeam,
        },
        uid: finding.uid ?? '',
        fid: finding.id,
    };
};

export const convertFindingViewToNotificationData = (
    text: string,
    copySecOpsTeam: boolean,
    deliveryStrategyKind: DeliveryStrategyKind,
    includeFinding = false,
    findingInsight?: FindingsInsightsDto,
    user?: Employee,
    finding?: FindingDto,
): CreateNotificationDto => {
    const uid = findingInsight?.user_id ?? user?.id ?? finding?.uid;
    return {
        channel: deliveryStrategyKind,
        content: {
            kind: ContentStrategyKind.RAW,
            content: text,
        },
        options: {
            copySecOpsTeam: copySecOpsTeam,
        },
        uid: uid ?? undefined,
        fid: findingInsight ? findingInsight?.id : finding?.id,
        includeFinding,
    };
};

export const createNotificationDataArray = (
    findings: FindingDto[],
    text: string,
    copySecOpsTeam: boolean,
    strategy: DeliveryStrategyKind,
): CreateNotificationDto[] => {
    return findings?.map((finding) => convertFindingToNotificationData(finding, text, copySecOpsTeam, strategy));
};

export const getFilledBars = (percentage: number) => {
    if (percentage <= 68) {
        return 1;
    } else if (percentage <= 70) {
        return 2;
    } else if (percentage <= 80) {
        return Math.ceil((percentage - 70) / 2) + 2;
    } else if (percentage <= 90) {
        return Math.ceil((percentage - 80) / 2) + 7;
    } else if (percentage <= 100) {
        return Math.ceil((percentage - 90) / 2) + 12;
    } else {
        return 17;
    }
};

export const renderBars = (percentage: number) => {
    const bars = [];
    const totalBars = 17;

    const colorRanges = [
        { color: 'secondary-accent-red-4', bars: [1, 2] },
        { color: 'secondary-accent-orange-3', bars: [3, 4, 5, 6, 7] },
        { color: 'secondary-accent-yellow-3', bars: [8, 9, 10, 11, 12] },
        { color: 'primary-500', bars: [13, 14, 15, 16, 17] },
    ];

    const filledBars = getFilledBars(percentage);
    for (let i = totalBars; i >= 1; i--) {
        const isFilled = i <= filledBars;

        const barColor = isFilled ? colorRanges.find((range) => range.bars.includes(i))?.color : 'hover-login-cell';

        bars.push(<div key={i} className={`vertical-bars__no-color bg-${barColor}`} />);
        if (i === 13) {
            bars.push(<div key="hr" className="hr" />);
        }
    }
    return bars;
};

export const toLineGraphData = (data: BaseMetricResults) =>
    Object.values(data)
        ?.map((item) => item.value)
        ?.reverse();

export const toRiskContributorsData = (data: BaseMetricResults) => [
    Math.max(5, (data?.[TOP_RISK_CONTRIBUTORS_TOOLING_LABEL]?.value ?? 0) * 100),
    Math.max(5, (data?.[TOP_RISK_CONTRIBUTORS_TRAINING_LABEL]?.value ?? 0) * 100),
    Math.max(5, (data?.[TOP_RISK_CONTRIBUTORS_VULN_LABEL]?.value ?? 0) * 100),
    Math.max(5, (data?.[TOP_RISK_CONTRIBUTORS_MFA_LABEL]?.value ?? 0) * 100),
];

export const transformDataToLabelValue = (
    data: TrendingMetricResults | BaseMetricResults,
    labelMap?: Record<string, string>,
): DataProp[] => {
    const { t } = useI18n();
    return _.map(data, (item) => ({
        label: labelMap ? labelMap[item.label] : t(item.label),
        value: item.value,
    }));
};

export const extractInfoFromConnectors = (
    connectors: ConnectorUpsertDto[],
    providers: {
        [x: string]: ProviderDto;
    },
    providerNameIntegrationMap: any,
) => {
    const extractedInfo = connectors?.map((connector) => {
        const provider = Object.values(providers)?.filter((provider) => provider?.id === connector?.pid)?.[0];
        const providerContentfulData = providerNameIntegrationMap?.[provider?.displayValue];
        const id = connector.pid ?? '';
        const cid = connector.id ?? '';
        const category = provider?.categories;
        const status = connector?.status;
        const title: string = providerContentfulData?.name || provider?.displayValue;
        const imageUrl = _.get(providerContentfulData, 'logo.fields.file.url');
        const syncTime = connector?.syncIntervalSeconds?.toString();
        const lastRunTime = connector.lastRunTime;
        const credentialStatus = connector.credentialStatus;
        return {
            id,
            category,
            domain: providerNameIntegrationMap?.[provider?.displayValue]?.domain,
            cid,
            status,
            title,
            imageUrl,
            connector,
            provider,
            syncTime,
            lastRunTime,
            credentialStatus,
        };
    });

    return extractedInfo;
};

export const generateHealthScoreConfigurationFactors = () => {
    const values = [];
    const uniqueValues: { [key: string]: boolean } = {};

    for (let i = 1; i <= 5; i += 0.1) {
        let formattedValue;

        if (i >= 2 && i < 5) {
            const roundedValue = Math.round(i);
            if (!uniqueValues[roundedValue]) {
                formattedValue = `${roundedValue} x`;
                uniqueValues[roundedValue] = true;
            }
        } else {
            formattedValue = i % 1 === 0 ? `${Math.round(i)} x` : `${i.toFixed(1)} x`;
        }

        if (formattedValue) {
            values.push({ id: values.length + 1, value: formattedValue });
        }
    }
    return values;
};

export const generateDropdownValues = () => {
    return Array.from({ length: 31 }, (_, index) => (index + 1).toString());
};

export const getServiceInfoByKey = (data: ServiceProfile[], key: string) => {
    for (const entry of data) {
        if (entry.serviceKey === key || entry?.aliases?.includes(key)) {
            return {
                logo: entry.logo as string,
                displayValue: entry.displayValue as string,
            };
        }
    }
    return null;
};

export const getMFAChipProps = (MFARating: MfaRating) => {
    switch (MFARating) {
        case _MfaRating.Enum.SECURE:
            return { color: 'vizualization-green-300', variantValue: 'ACTIVE' };
        case _MfaRating.Enum.INSECURE:
        case _MfaRating.Enum.UNKNOWN:
            return { color: 'secondary-accent-orange-3', variantValue: 'PAUSED' };
        case _MfaRating.Enum.MISSING:
            return { color: 'secondary-accent-red-1', variantValue: 'ERROR' };
        default:
            return { color: 'secondary-accent-orange-3', variantValue: 'PAUSED' };
    }
};

export const getMFAStatusProps = (MFAStatus: MfaStatus) => {
    switch (MFAStatus) {
        case MfaStatus.ENABLED_SECURE:
        case MfaStatus.ENABLED_UNKNOWN:
            return { color: 'vizualization-green-300', variantValue: 'ACTIVE' };
        case MfaStatus.ENABLED_INSECURE:
            return { color: 'secondary-accent-orange-3', variantValue: 'PAUSED' };
        case MfaStatus.DISABLED:
            return { color: 'secondary-accent-red-1', variantValue: 'ERROR' };
        case MfaStatus.NOT_ENROLLED:
            return { color: 'text-low-emphasis', variantValue: 'GHOST' };
        default:
            return { color: 'secondary-accent-orange-3', variantValue: 'PAUSED' };
    }
};

export const getMFATooltipProps = (row: UserCoverageSummary): { icon: React.ReactNode; value: string }[] => {
    const customTooltipContent: { icon: React.ReactNode; value: string }[] = [];

    row.mfaFactors?.forEach((mfaFactor) => {
        const color = getMFAChipProps((mfaFactor.secure as MfaRating) ?? 'UNKNOWN').color;

        switch (mfaFactor.factorType) {
            case 'call':
                customTooltipContent.push({
                    icon: <IoCallOutline className={`text-${color} users-insights-modal__react-svg`} />,
                    value: mfaFactor.factorType,
                });
                break;
            case 'email':
                customTooltipContent.push({
                    icon: <MdOutlineEmail className={`text-${color} users-insights-modal__react-svg`} />,
                    value: mfaFactor.factorType,
                });
                break;
            case 'push':
                customTooltipContent.push({
                    icon: <VscBellDot className={`text-${color} users-insights-modal__react-svg`} />,
                    value: mfaFactor.factorType,
                });
                break;
            case 'question':
                customTooltipContent.push({
                    icon: <LuShieldQuestion className={`text-${color} users-insights-modal__react-svg`} />,
                    value: mfaFactor.factorType,
                });
                break;
            case 'signed_nonce':
                customTooltipContent.push({
                    icon: <BiEditAlt className={`text-${color} users-insights-modal__react-svg`} />,
                    value: mfaFactor.factorType,
                });
                break;
            case 'sms':
                customTooltipContent.push({
                    icon: <MdOutlineTextsms className={`text-${color} users-insights-modal__react-svg`} />,
                    value: mfaFactor.factorType,
                });
                break;
            case 'token:hardware':
            case 'token:hotp':
            case 'token:software:totp':
            case 'token':
                customTooltipContent.push({
                    icon: <MdOutlinePassword className={`text-${color} users-insights-modal__react-svg`} />,
                    value: mfaFactor.factorType,
                });
                break;
            case 'u2f':
                customTooltipContent.push({
                    icon: <MdOutlineUsb className={`text-${color} users-insights-modal__react-svg`} />,
                    value: mfaFactor.factorType,
                });
                break;
            case 'web':
            case 'webauthn':
                customTooltipContent.push({
                    icon: <BsGlobe className={`text-${color} users-insights-modal__react-svg`} />,
                    value: mfaFactor.factorType,
                });
                break;
            default:
                customTooltipContent.push({
                    icon: <BsShieldLock className={`text-${color} users-insights-modal__react-svg`} />,
                    value: mfaFactor.factorType ?? '',
                });
                break;
        }
    });
    return customTooltipContent;
};

export const sortFindingSpecsData = (data: any[]) => {
    return data?.slice().sort((a, b) => {
        const providerComparison = (b?.provider ?? '').localeCompare(a?.provider ?? '');
        if (providerComparison !== 0) {
            return providerComparison;
        }

        return (a?.severity ?? '').localeCompare(b?.severity ?? '');
    });
};

export const generateSentryEvent = (component: string, tag: AmpSentryTags): AmpSentryEvent => {
    const { selectedTenant, user } = useSelector((state: RootState) => state.auth);

    const event: AmpSentryEvent = {
        message: `[${selectedTenant?.displayValue}] : No data for ${component}`,
        tags: { [tag]: component },
        user: { userEmail: user?.email, tid: selectedTenant?.id },
        level: 'info',
    };

    return event;
};

export const getToastBody = (variant: string): string => {
    const variantMessages: Record<string, string> = {
        [ENGAGE_VARIANT]: ENGAGE_SUCCESS_MSG,
        [ACCEPT_FINDINGS_VARIANT]: ACCEPT_FINDINGS_SUCCESS_MSG,
        [FALSE_POSITIVE_VARIANT]: FALSE_POSITIVE_SUCCESS_MSG,
        [OPEN_VARIANT]: OPEN_SUCCESS_MSG,
        [ERROR_VARIANT]: DEFAULT_ERROR_MSG,
    };

    return variantMessages?.[variant] || variant || '';
};

export const getAssetDomains = (cid: string, providerDisplayValue: string, tenantSettings: AmpSettingsMap) => {
    const domains: string[] = [];
    if (!_.isEmpty(tenantSettings)) {
        const providerSettings = AMP_SETTINGS[providerDisplayValue?.toLowerCase() as keyof typeof AMP_SETTINGS];
        if (providerSettings) {
            const checkKey = (rawKey: string, domain: string) => {
                const assetColumnKey: TypedAmpSettingsKey<boolean> | undefined = _.get(providerSettings, rawKey);
                const connectorSetting = assetColumnKey && tenantSettings.getConnectorSetting(cid, assetColumnKey);
                if (connectorSetting) {
                    domains.push(domain);
                }
            };
            checkKey('ETL_DEVICE_SECURITY_ENABLED', 'Endpoint Security');
            checkKey('ETL_VULN_ENABLED', 'Vulnerability Management');
            checkKey('ETL_DEVICE_MANAGEMENT_ENABLED', 'Endpoint Management');
        }
    }
    return domains;
};
