import './styles.scss';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { TableColumn } from 'react-data-table-component';
import { Table } from '../Table';
import { Typography } from 'Components/atoms';
import { ACTIVE_ENGAGEMENTS_MODAL_COLUMNS, ACTIVE_ENGAGEMENTS_MODAL_HEADER } from 'Core-utils/constants/constants';
import { ActiveEngagement } from '@ampsec/platform-client';
import { USERS_DASHBOARD_ROUTE } from 'Core-utils/routes';
import moment from 'moment';

const ROWS_PER_PAGE_OPTIONS = [10, 20, 30];

interface ActiveEngagementsModalProps {
    specId: string;
    show: boolean;
    onClose: () => void;
}

type ActiveEngagements = {
    uid: string;
    userName: string;
    scheduledDate?: string | null;
    numOfReminders: number;
    openFor?: string | null;
    expirationIn?: string | null;
};

const columns: TableColumn<ActiveEngagements>[] = [
    {
        id: 'userName',
        name: (
            <Typography variant="subtitle6" className="m-0">
                {ACTIVE_ENGAGEMENTS_MODAL_COLUMNS.userName}
            </Typography>
        ),
        cell: (row: ActiveEngagements) => (
            <Link
                to={`${USERS_DASHBOARD_ROUTE}/${row.uid}`}
                className="engagement-clickable text-decoration-none text-truncate"
            >
                <Typography variant="body5" color="text-high-emphasis">
                    {row.userName}
                </Typography>
            </Link>
        ),
    },
    {
        id: 'scheduledDate',
        name: (
            <Typography variant="subtitle6" className="m-0">
                {ACTIVE_ENGAGEMENTS_MODAL_COLUMNS.scheduledDate}
            </Typography>
        ),
        cell: (row: ActiveEngagements) => (
            <Typography variant="subtitle3" className="m-0">
                {row.scheduledDate ? moment(row.scheduledDate).fromNow(true) : '-'}
            </Typography>
        ),
    },
    {
        id: 'noOfReminders',
        name: (
            <Typography variant="subtitle6" className="m-0">
                {ACTIVE_ENGAGEMENTS_MODAL_COLUMNS.noOfReminders}
            </Typography>
        ),
        center: true,
        cell: (row: ActiveEngagements) => (
            <Typography variant="subtitle3" className="m-0">
                {row.numOfReminders + 1}
            </Typography>
        ),
    },
    {
        id: 'openFor',
        name: (
            <Typography variant="subtitle6" className="m-0">
                {ACTIVE_ENGAGEMENTS_MODAL_COLUMNS.openFor}
            </Typography>
        ),
        cell: (row: ActiveEngagements) => (
            <Typography variant="subtitle3" className="m-0">
                {row.openFor ? moment(row.openFor).fromNow(true) : '-'}
            </Typography>
        ),
    },
    {
        id: 'expirationIn',
        name: (
            <Typography variant="subtitle6" className="m-0">
                {ACTIVE_ENGAGEMENTS_MODAL_COLUMNS.expirationIn}
            </Typography>
        ),
        center: true,
        cell: (row: ActiveEngagements) => (
            <Typography variant="subtitle3" className="m-0">
                {row.openFor && row.expirationIn ? moment(row.expirationIn).fromNow(true) : '-'}
            </Typography>
        ),
    },
];

const ActiveEngagementsTable = ({ specId }: { specId: string }) => {
    const transformer = (data: ActiveEngagement): ActiveEngagements => ({
        uid: data.uid,
        userName: `${data.firstName} ${data.lastName}`,
        scheduledDate: data.scheduledDate,
        numOfReminders: data.numberOfReminders,
        openFor: data.engagementStartedAt,
        expirationIn: data?.expirationAt,
    });

    return (
        <Table
            columns={columns}
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            cacheKey="flowSpecs"
            operation="getActiveEngagements"
            transformer={(dataItem) => ({ ...dataItem, data: dataItem.data.map(transformer) })}
            showSubHeader={false}
            filter={{ specId }}
            tableBodyHeight="295px"
        />
    );
};

const ActiveEngagementsModal = ({ specId, show, onClose }: ActiveEngagementsModalProps) => {
    return (
        <Modal className="active-engagements-modal" show={show} onHide={onClose} centered size="lg">
            <Modal.Header
                className="active-engagements-modal__header text-text-high-emphasis"
                closeButton
                closeVariant="white"
            >
                <Typography className="m-0 text-uppercase" variant="h4">
                    {ACTIVE_ENGAGEMENTS_MODAL_HEADER}
                </Typography>
            </Modal.Header>
            <Modal.Body className="active-engagements-modal__body border-top-0 p-0 rounded-bottom">
                <ActiveEngagementsTable specId={specId} />
            </Modal.Body>
        </Modal>
    );
};

export default ActiveEngagementsModal;
