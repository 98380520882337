import { REPORTS } from '@ampsec/platform-client';
import { CustomCard } from 'Components/molecules';
import { IconWithTypography } from 'Components/molecules';
import { RadarChart } from 'Components/molecules';
import {
    TOP_RISK_CONTRIBUTORS,
    TOP_RISK_CONTRIBUTORS_MFA_LABEL,
    TOP_RISK_CONTRIBUTORS_TOOLING_LABEL,
    TOP_RISK_CONTRIBUTORS_TRAINING_LABEL,
    TOP_RISK_CONTRIBUTORS_VULN_LABEL,
} from 'Core-utils/constants/constants';
import ErrorHandlerAndLoaderWrapper from 'Wrappers/ErrorHandlerandLoaderWrapper';
import { getMetricsReportsData } from 'Apis/library';
import { BaseMetricResults } from 'Core-utils/types/types';
import { useApiObjectData } from 'Hooks/useApiObjectData';
import riskContributorsIcon from 'Assets/icons/riskContributors.svg';
import _ from 'lodash';
import './styles.scss';

const COMPONENT_IDENTIFIER = 'Organization Top Risk Contributors';

const TopRiskContributors = () => {
    const { data, error, isLoading, refresh } = useApiObjectData<BaseMetricResults, BaseMetricResults>(
        REPORTS.TOP_RISK_CONTRIBUTORS,
        getMetricsReportsData,
        (a) => a,
        { department: 'ALL' },
    );

    const datasets = [
        {
            data: [
                Math.max(5, (data?.[TOP_RISK_CONTRIBUTORS_TOOLING_LABEL]?.value ?? 0) * 100),
                Math.max(5, (data?.[TOP_RISK_CONTRIBUTORS_TRAINING_LABEL]?.value ?? 0) * 100),
                Math.max(5, (data?.[TOP_RISK_CONTRIBUTORS_VULN_LABEL]?.value ?? 0) * 100),
                Math.max(5, (data?.[TOP_RISK_CONTRIBUTORS_MFA_LABEL]?.value ?? 0) * 100),
            ],
            backgroundColor: 'rgba(39, 32, 54, 0.4)',
            borderColor: '#7358BB',
            name: 'Organization',
        },
    ];
    return (
        <div className="top-risk-contributors h-100" data-testid="top-risk-contributors">
            <CustomCard
                cardType="oneThird"
                title={<IconWithTypography isRow={true} label={TOP_RISK_CONTRIBUTORS} labelColor="primary" />}
                content={
                    <div className="top-risk-contributors__error-container">
                        <ErrorHandlerAndLoaderWrapper
                            error={error}
                            isLoading={isLoading}
                            render={() => <RadarChart datasets={datasets} />}
                            handleRefresh={refresh}
                            isEmpty={_.isEmpty(data)}
                            ImageSrc={riskContributorsIcon}
                            component={COMPONENT_IDENTIFIER}
                        />
                    </div>
                }
            />
        </div>
    );
};

export default TopRiskContributors;
