import { ChangeEvent, useContext, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { Card, Dropdown } from 'react-bootstrap';
import { RiArrowDownSLine } from '@react-icons/all-files/ri/RiArrowDownSLine';
import { RiDeleteBin6Line } from '@react-icons/all-files/ri/RiDeleteBin6Line';
import { Accordion } from 'Components/atoms';
import { DynamicDropdown, ToggleTextPanel } from 'Components/molecules';
import { Table } from 'Components/organisms';
import { ConfirmationModal } from 'Components/molecules';
import { SHARE_SECURITY_POSTURE_DATA } from './shareSecurityPostureData';
import InviteSubHeaderContent from './InvitesSubHeaderContent';
import {
    COMPANY_PARAMETERS,
    SETTINGS,
    DATA_GRID_HEIGHT,
    USER_TABLE_ROWS_PER_PAGE_OPTIONS,
    BUTTON_LABEL_MAP,
    MODAL_CONTENT_MAP,
    SUCCESS_VARIANT,
    CONNECTOR_SETTINGS_SAVED,
    HEALTH_SCORE_CONFIGURATION,
    HEALTH_SCORE_CONFIGURATION_TABS,
    USER_MANAGEMENT,
    DISABLE_NOTIFICATIONS,
    DISABLE_NOTIFICATIONS_CAPTION,
    YES_DELETE_IT,
    USER_RBAC_OPTIONS,
    ACCESS_OPTIONS,
} from 'Core-utils/constants/constants';
import { AMP_SETTINGS, AgentDto, AgentStatus, AuthRole, KIND } from '@ampsec/platform-client';
import { useApiData } from 'Hooks/useApiData';
import {
    UpdateAgent,
    createSetting,
    deleteAgent,
    getAgentsList,
    getTenantSettings,
    inviteAgent,
    updateSettings,
} from 'Apis/library';
import { AmpSettingDto, AmpSettingUpsertDto } from '@ampsec/platform-client';
import { Dispatch, RootState } from 'Rdx/store';
import { useDispatch, useSelector } from 'react-redux';
import PageErrorHandlerAndLoaderWrapper from 'Wrappers/PageErrorHandlerAndLoaderWrapper';
import { ToastsContext } from 'Rdx/contexts/ToastsContext';
import { ConfigurationTabs } from 'Components/organisms';
import HighRiskCohortConfigurationTable from './HighRiskCohortConfigurationTable';
import HealthScoreConfigurationTable from './HealthScoreConfigurationTable';
import { TableColumn } from 'react-data-table-component';
import { Typography } from 'Components/atoms';
import { ACCESS, ADDED_DATE, EMAIL_ID, LAST_LOGGED_IN, NAME, STATUS } from 'Core-utils/constants/constants';
import { Chip } from 'Components/atoms';
import RoleDropdown from './RoleDropdown';
import { Avatar } from 'Components/atoms';
import { MenuItem } from 'Core-utils/types';
import { useApiObjectData } from 'Hooks/useApiObjectData';
import { AgentAuthRoles } from '@ampsec/platform-client/src/dto/enums/auth.role';
import { CustomTooltip } from 'Components/atoms';
import './styles.scss';
import { useI18n } from 'Hooks/useI18n';

export type TenantAmpSetting = '' | keyof typeof AMP_SETTINGS.tenant;

const DEFAULT_ACTIVE_KEYS = ['0', '1', '2'];

const SettingConfirmationModal = ({
    content,
    getAmpSettingValue,
    savingSettings,
    settingKey,
    showConfirmationModal,
    title,
    onCallback,
    onClose,
    onConfirm,
    onError,
}: {
    content?: string;
    getAmpSettingValue: (settingKey: string) => any;
    savingSettings?: boolean;
    settingKey: string;
    showConfirmationModal: boolean;
    title: string;
    onCallback?: () => void;
    onClose?: (newAmpSetting?: AmpSettingDto) => void;
    onConfirm?: () => Promise<AmpSettingDto | undefined>;
    onError?: (error: string) => void;
}) => {
    const handleConfirmAction = () => {
        if (settingKey === '') {
            return;
        }

        onConfirm &&
            onConfirm()
                .then((data) => onClose && onClose(data))
                .catch((error) => onError && onError(error.message))
                .finally(() => onCallback && onCallback());
    };

    const renderPrimaryButtonLabel = () => {
        return settingKey === 'notifications.enabled'
            ? BUTTON_LABEL_MAP.NOTIFICATIONS_ENABLED?.[
                  getAmpSettingValue(AMP_SETTINGS.tenant.NOTIFICATIONS_ENABLED.id)?.toString()
              ] || ''
            : 'Yes, Save it';
    };

    const handleExit = () => onClose && onClose(undefined);

    return (
        <ConfirmationModal
            cancelButtonDisabled={savingSettings}
            modalTitle={title}
            modalContent={content}
            onPrimaryButtonClick={handleConfirmAction}
            primaryButtonLabel={renderPrimaryButtonLabel()}
            primaryButtonDisabled={savingSettings}
            show={showConfirmationModal}
            onClose={handleExit}
            buttonVariant="primary"
        />
    );
};

const SettingsPage = () => {
    const { t } = useI18n();
    const { data: tenantSettings, isLoading, refresh: refreshTenantSettings, error: settingsError } = useApiData<
        { [key: string]: AmpSettingDto },
        { [key: string]: AmpSettingDto }
    >('TENANT_SETTINGS', getTenantSettings, (a) => a);
    const [error, setError] = useState<string>('');
    const enableBanner = useSelector((state: RootState) => state.featureModel.enableBanner);
    const [settingKey, setSettingKey] = useState<string | null>();
    const [savingSettings, setSavingSettings] = useState<boolean>(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
    const { addToast } = useContext(ToastsContext);
    const [_initialSettingsValues, setInitialSettingsValues] = useState<{ [key: string]: AmpSettingDto } | null>();
    const [userSettingInput, setUserSettingInput] = useState<{ [key: string]: string | boolean }>({});
    const [userData, setUserData] = useState(SHARE_SECURITY_POSTURE_DATA);
    const [showModal, setShowModal] = useState({
        accessUser: false,
        removeUser: false,
    });
    const [showConfirmationUserManagementModal, setShowConfirmationUserManagementModal] = useState<boolean>(false);
    const [idToDelete, setIdToDelete] = useState<string>('');
    const dispatch = useDispatch<Dispatch>();
    const [selectedDelay, setSelectedDelay] = useState<string>(AMP_SETTINGS.tenant.WELCOME_MESSAGE_DELAY.defaultValue);

    const welcomeMessageDropdownOptions = [
        'Immediate',
        '1 minutes',
        '2 minutes',
        '3 minutes',
        '4 minutes',
        '5 minutes',
        '6 minutes',
        '7 minutes',
        '8 minutes',
        '9 minutes',
        '10 minutes',
    ];

    const getAmpSettingValue = (settingKey: string): any => {
        if (!settingKey || (tenantSettings && _.isEmpty(tenantSettings))) {
            return true;
        }
        return tenantSettings && (tenantSettings as any)[settingKey]?.value;
    };

    const handleToggle = (type: string, value: boolean) => {
        setShowConfirmationModal((visible) => !visible);
        setSettingKey(type);
        setUserSettingInput((current) => ({ ...current, [type]: value }));
    };
    const _handleSaveClick = (setting: string) => () => {
        setSettingKey(setting);
        setShowConfirmationModal(true);
    };

    const handleMoreActions = (item: string, id: string) => {
        // TODO: Need to Integrate with apis
        if (item === 'REMOVE USER') {
            const modalObj = {
                ...showModal,
                accessUser: false,
                removeUser: true,
            };
            setShowModal(modalObj);

            const updatedUserData = userData.filter((user) => user.id.toString() !== id);
            setUserData(updatedUserData);
        } else {
            const newStatus = item === 'ACTIVATE USER' ? 'ACTIVE' : 'SUSPENDED';
            const updatedUserData = userData.map((user) => {
                if (user.id.toString() === id) {
                    user.status = newStatus;
                }
                return user;
            });
            setUserData(updatedUserData);
        }
    };

    const handleUpdateAccess = (id: number, value: string) => {
        const modalObj = {
            ...showModal,
            accessUser: true,
            removeUser: false,
        };
        setShowModal(modalObj);
        const updatedUserData = userData.map((user) => {
            if (user.id === id) {
                user.access = value;
            }
            return user;
        });
        setUserData(updatedUserData);
    };

    const handleCloseModal = () => {
        const modalObj = {
            ...showModal,
            accessUser: false,
            removeUser: false,
        };
        setShowModal(modalObj);
    };

    const handleAccessChange = () => {
        handleUpdateAccess;
        const modalObj = {
            ...showModal,
            accessUser: false,
            removeUser: false,
        };
        setShowModal(modalObj);
    };

    const handleRemoveUser = () => {
        handleMoreActions;
        const modalObj = {
            ...showModal,
            accessUser: false,
            removeUser: false,
        };
        setShowModal(modalObj);
    };

    const handleTenantList = () => {
        if (isLoading || !tenantSettings || Object.keys(tenantSettings).length === 0) {
            return;
        }
        if (!Object.keys(tenantSettings)?.includes(AMP_SETTINGS.tenant.NOTIFICATIONS_ENABLED.id)) {
            const notificationsEnabledSetting = (tenantSettings as any)[AMP_SETTINGS.tenant.NOTIFICATIONS_ENABLED.id];
            if (notificationsEnabledSetting === undefined || notificationsEnabledSetting === null) {
                createSetting(
                    AMP_SETTINGS.tenant.NOTIFICATIONS_ENABLED.id,
                    AMP_SETTINGS.tenant.NOTIFICATIONS_ENABLED.defaultValue,
                ).catch((error) => {
                    setError(error.message);
                });
            }
        }
    };

    const _handleSettingChange = (settingKey: string) => (value: string) => {
        setUserSettingInput((current) => ({ ...current, [settingKey]: value }));
    };

    const handleToggleNotificationMode = (ev: ChangeEvent<HTMLInputElement>) => {
        handleToggle(AMP_SETTINGS.tenant.NOTIFICATIONS_ENABLED.id, !ev.target.checked);
    };
    const _handleToggleSlackQaAllMessages = (ev: ChangeEvent<HTMLInputElement>) =>
        handleToggle(AMP_SETTINGS.tenant.SLACK_QA_ALL_MESSAGES.id, ev.target.checked);

    const passthrough = (a: any) => a;
    const handleModalClose = (newAmpSetting?: AmpSettingDto) => {
        if (newAmpSetting) {
            addToast({
                severity: SUCCESS_VARIANT,
                body: `${CONNECTOR_SETTINGS_SAVED}`,
            });
        }
        setSettingKey(null);
        setShowConfirmationModal(false);
    };
    const handleModalError = (error: string) => setError(error);

    const getAmpSettingId = (settingKey: string) => {
        if (!settingKey || tenantSettings === undefined || tenantSettings === null) {
            return '';
        }
        return ((tenantSettings as any)[settingKey]?.id as string) || '';
    };

    const transformSettingValue = (settingKey: string): any => {
        if (!settingKey || !userSettingInput) {
            return '';
        }
        switch (settingKey) {
            case AMP_SETTINGS.tenant.SLACK_QA_ALL_MESSAGES.id:
            case AMP_SETTINGS.tenant.NOTIFICATIONS_ENABLED.id:
                return userSettingInput[settingKey];
            case AMP_SETTINGS.tenant.DEFAULT_NOTIFICATION_CONNECTOR_ID.id:
                return {
                    cid: userSettingInput[settingKey],
                    displayValue: 'Default Notification Connector ID',
                };
            default:
                return getAmpSettingValue(settingKey);
        }
    };

    const handleConfirmModal = () => {
        setSavingSettings(true);
        if (!settingKey) {
            throw new Error('No setting was changed');
        }
        const ampSettingId = getAmpSettingId(settingKey);

        const existingSetting = Object.keys(tenantSettings as any).find((setting) => setting === settingKey);

        // If the setting already exists, update it. Otherwise, create it.
        if (existingSetting !== undefined) {
            return updateSettings(ampSettingId, {
                id: ampSettingId,
                key: settingKey,
                value: transformSettingValue(settingKey),
            } as AmpSettingUpsertDto);
        } else {
            return createSetting(settingKey, transformSettingValue(settingKey));
        }
    };

    const handleModalCallback = () => {
        refreshTenantSettings();
        setSavingSettings(false);
    };

    const modalContent = () => {
        switch (settingKey) {
            case AMP_SETTINGS.tenant.NOTIFICATIONS_ENABLED.id:
                return MODAL_CONTENT_MAP.NOTIFICATIONS_ENABLED?.[
                    getAmpSettingValue(AMP_SETTINGS.tenant.NOTIFICATIONS_ENABLED.id)?.toString()
                ];
            case AMP_SETTINGS.tenant.SLACK_QA_ALL_MESSAGES.id:
            case AMP_SETTINGS.tenant.DEFAULT_NOTIFICATION_CONNECTOR_ID.id:
                return 'Are you sure you want to save this setting?';
            default:
                return `UNKNOWN SETTING KEY: ${settingKey}`;
        }
    };

    const modalTitle = () => {
        switch (settingKey) {
            case AMP_SETTINGS.tenant.NOTIFICATIONS_ENABLED.id:
                return 'Disable Notifications';
            case AMP_SETTINGS.tenant.SLACK_QA_ALL_MESSAGES.id:
                return 'Send all engagements to Slack';
            case AMP_SETTINGS.tenant.DEFAULT_NOTIFICATION_CONNECTOR_ID.id:
                return 'Default Connector';
            default:
                return `UNKNOWN SETTING KEY: ${settingKey}`;
        }
    };

    useEffect(() => {
        handleTenantList();
    }, [tenantSettings, isLoading]);

    useEffect(() => {
        if (!tenantSettings) {
            return;
        }

        const tempInitialSettingsValues: { [key: string]: AmpSettingDto } = {};
        Object.keys(tenantSettings as { [key: string]: AmpSettingDto }).forEach((setting) => {
            tempInitialSettingsValues[setting] = (tenantSettings as any)[setting];
        });
        setInitialSettingsValues(tempInitialSettingsValues);
        dispatch.featureModel.setBanner(!error && !getAmpSettingValue(AMP_SETTINGS.tenant.NOTIFICATIONS_ENABLED.id));
    }, [tenantSettings]);

    const handleCloseUserManagementModal = () => {
        setShowConfirmationUserManagementModal(false);
    };

    const SettingsContent = () => {
        const [dropdownValue, setDropdownValue] = useState<string>(ACCESS_OPTIONS[0].value);
        const [inputValue, setInputValue] = useState<string>('');
        const [isValidEmail, setIsValidEmail] = useState<boolean>(true);
        const [refreshData, setRefreshData] = useState<boolean>(false);

        const [disableInvite, setDisableInvite] = useState<boolean>(false);

        const { data: fetchInvitedAgents, refresh: refreshInvitedAgents } = useApiObjectData<AgentDto[], AgentDto[]>(
            KIND.AGENTS,
            getAgentsList,
            (a) => a,
            {
                status: AgentStatus.INVITED,
            },
        );
        const { data: fetchAgents, refresh } = useApiObjectData<AgentDto[], AgentDto[]>(
            KIND.AGENTS,
            getAgentsList,
            (a) => a,
        );
        const mapFetchedAgentItem = (item: AgentDto): MenuItem => {
            return {
                id: item.id,
                displayValue: item?.email,
                value: item?.email,
            };
        };
        const invitedList = useMemo(() => {
            if (Array.isArray(fetchInvitedAgents)) {
                const updatedList = fetchInvitedAgents?.map((item: AgentDto) => mapFetchedAgentItem(item));
                return updatedList;
            }
        }, [fetchInvitedAgents]);
        const handleInviteUser = () => {
            isValidEmail &&
                inviteAgent({
                    email: inputValue,
                    role: dropdownValue.toUpperCase() as AgentAuthRoles,
                }).then(() => {
                    setDisableInvite(true);
                    setRefreshData((refreshData) => !refreshData);
                    refreshInvitedAgents();
                    refresh();
                    setInputValue('');
                    setDisableInvite(false);
                });
        };

        const handleInputChange = (event: any) => {
            setInputValue(event.target.value);
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            setIsValidEmail(emailRegex.test(event.target.value));
        };

        const handleDeleteUser = (id: string) => {
            setShowConfirmationUserManagementModal(true);
            setIdToDelete(id);
        };

        const handleUserDeleteClick = () => {
            deleteAgent(idToDelete).then(() => {
                setRefreshData((refreshData) => !refreshData);
                refreshInvitedAgents();
                refresh();
            });
            setShowConfirmationUserManagementModal(false);
        };

        const handleDropdownChange = (eventKey: string | null, _e: any) => {
            setDropdownValue(eventKey ?? '');
        };

        const handleDelayChange = (option: string) => {
            if (getAmpSettingId(AMP_SETTINGS.tenant.WELCOME_MESSAGE_DELAY.id)) {
                updateSettings(getAmpSettingId(AMP_SETTINGS.tenant.WELCOME_MESSAGE_DELAY.id), {
                    id: getAmpSettingId(AMP_SETTINGS.tenant.WELCOME_MESSAGE_DELAY.id),
                    key: AMP_SETTINGS.tenant.WELCOME_MESSAGE_DELAY.id,
                    value: option,
                } as AmpSettingUpsertDto)
                    .then(() => setSelectedDelay(option))
                    .catch((error) => {
                        setError(error.message);
                    });
            }
        };

        useEffect(() => {
            if (isLoading || !tenantSettings || Object.keys(tenantSettings).length === 0) {
                return;
            }
            if (!Object.keys(tenantSettings)?.includes(AMP_SETTINGS.tenant.WELCOME_MESSAGE_DELAY.id)) {
                const welcomeMsgDelaySetting = (tenantSettings as any)[AMP_SETTINGS.tenant.WELCOME_MESSAGE_DELAY.id];
                if (welcomeMsgDelaySetting === undefined || welcomeMsgDelaySetting === null) {
                    createSetting(
                        AMP_SETTINGS.tenant.WELCOME_MESSAGE_DELAY.id,
                        AMP_SETTINGS.tenant.WELCOME_MESSAGE_DELAY.defaultValue,
                    ).catch((error) => {
                        setError(error.message);
                    });
                }
            } else {
                setSelectedDelay(
                    (tenantSettings as { [key: string]: AmpSettingDto })?.[AMP_SETTINGS.tenant.WELCOME_MESSAGE_DELAY.id]
                        ?.value as string,
                );
            }
        }, [tenantSettings]);

        const shareSecurityPostureColumns = (): TableColumn<AgentDto>[] => {
            return [
                {
                    id: 'firstName',
                    name: <Typography variant="body2">{NAME}</Typography>,
                    sortable: true,
                    wrap: true,
                    cell: (row: AgentDto) => {
                        return row.firstName ? (
                            <Avatar
                                variant={'name_avatar'}
                                name={row?.firstName ? row?.firstName + ' ' + row?.lastName : ''}
                                image={row?.pictureUrl ?? undefined}
                            />
                        ) : (
                            <Typography variant="subtitle3" children=""></Typography>
                        );
                    },
                },
                {
                    id: 'createdAt',
                    name: <Typography variant="body2">{ADDED_DATE}</Typography>,
                    sortable: true,
                    wrap: true,
                    cell: (row: AgentDto) => {
                        return <Typography variant="subtitle3">{row.createdAt}</Typography>;
                    },
                },
                {
                    id: 'emailId',
                    name: <Typography variant="body2">{EMAIL_ID}</Typography>,
                    sortable: false,
                    wrap: true,
                    cell: (row: AgentDto) => {
                        return (
                            <CustomTooltip
                                id={`user-grid-email-${row.id}`}
                                label={row.email ?? ''}
                                labelColor="primary"
                                labelVariant="subtitle3"
                                overlayPlacement="left"
                                overlayContainerClassName="d-flex flex-column justify-content-center overflow-hidden w-100"
                                tooltipClassName="color-white text-white"
                            >
                                <Typography variant="subtitle3" style={{ width: '11rem' }} className="text-truncate">
                                    {row.email}
                                </Typography>
                            </CustomTooltip>
                        );
                    },
                },
                {
                    id: 'lastLoggedIn',
                    name: <Typography variant="body2">{LAST_LOGGED_IN}</Typography>,
                    sortable: false,
                    wrap: true,
                    cell: (row: AgentDto) => {
                        return <Typography variant="subtitle3">{row.lastLoggedIn?.toString()}</Typography>;
                    },
                },
                {
                    id: 'access',
                    name: <Typography variant="body2">{ACCESS}</Typography>,
                    sortable: false,
                    wrap: true,
                    cell: (row: AgentDto) => {
                        const [value, setValue] = useState<AuthRole>(row.role);

                        const handleDropdownChange = (eventKey: string | null, _e: any) => {
                            const agentToUpdate = fetchAgents?.find((item: AgentDto) => item.id === row.id);
                            if (agentToUpdate?.id) {
                                UpdateAgent({
                                    ..._.omit(agentToUpdate, 'pictureUrl'),
                                    role: eventKey?.toUpperCase() as AgentAuthRoles,
                                    pictureUrl: agentToUpdate.pictureUrl,
                                }).then(() => {
                                    setRefreshData((refreshData) => !refreshData);
                                    setValue(eventKey?.toUpperCase() as AuthRole);
                                });
                            }
                        };
                        return (
                            <RoleDropdown
                                dropDownItems={ACCESS_OPTIONS}
                                value={value}
                                onChange={handleDropdownChange}
                            />
                        );
                    },
                },
                {
                    id: 'status',
                    name: <Typography variant="body2">{STATUS}</Typography>,
                    sortable: false,
                    wrap: true,
                    cell: (row: AgentDto) => {
                        const [value, setValue] = useState<string>(row.status);
                        const handleUpdateStatus = async (rowId: string, status: AgentStatus) => {
                            const agentToUpdate = fetchAgents?.find((item: AgentDto) => item.id === rowId);
                            if (agentToUpdate?.id) {
                                try {
                                    await UpdateAgent({
                                        ..._.omit(agentToUpdate, 'pictureUrl'),
                                        status: status,
                                        pictureUrl: agentToUpdate.pictureUrl,
                                    });

                                    setRefreshData((refreshData) => !refreshData);
                                    return true;
                                } catch (error) {
                                    return false;
                                }
                            } else {
                                return false;
                            }
                        };

                        let isUpdateSuccess = false;

                        const handleDropdownChange = async (eventKey: string | null, _e: any) => {
                            switch (eventKey) {
                                case 'ACTIVATE USER':
                                    isUpdateSuccess = await handleUpdateStatus(row.id ?? '', AgentStatus.ACTIVE);
                                    if (isUpdateSuccess) {
                                        setValue('ACTIVE');
                                    }
                                    break;
                                case 'SUSPEND USER':
                                    isUpdateSuccess = await handleUpdateStatus(row.id ?? '', AgentStatus.SUSPENDED);
                                    if (isUpdateSuccess) {
                                        setValue('SUSPENDED');
                                    }
                                    break;
                                default:
                                    break;
                            }
                        };
                        const dropdownItems = USER_RBAC_OPTIONS.filter(
                            (option) => option.event !== value && option.event !== 'REMOVE',
                        );
                        return row.status !== AgentStatus.INVITED ? (
                            <Dropdown onSelect={handleDropdownChange}>
                                <Dropdown.Toggle className="border-0 text-text-low-emphasis d-flex gap-2 justify-content-center align-items-center">
                                    <Chip value={value} variant="status" />
                                    <RiArrowDownSLine />
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="p-0 w-100 gap-2 bg-body-color border-text-medium-emphasis custom-scrollbar dropdown__custom-menu">
                                    {dropdownItems.map((item) => (
                                        <Dropdown.Item
                                            key={item.id}
                                            eventKey={item.value}
                                            className="dropdown__menu-item d-flex align-items-center gap-2"
                                        >
                                            <Typography variant={'body5'} style={{ textTransform: 'uppercase' }}>
                                                {item.value}
                                            </Typography>
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        ) : (
                            <div className="d-flex ps-2">
                                <Chip value={row.status} variant="status" />
                            </div>
                        );
                    },
                },
                {
                    id: 'delete',
                    width: '5rem',
                    cell: (row: AgentDto) => {
                        return (
                            <RiDeleteBin6Line
                                size={20}
                                className="text-text-low-emphasis"
                                cursor="pointer"
                                onClick={() => handleDeleteUser(row.id)}
                            />
                        );
                    },
                },
            ];
        };

        return (
            <div className="settings-page d-flex flex-column pt-4 gap-4">
                <Typography variant="body11" color="text-high-emphasis">
                    {SETTINGS}
                </Typography>
                <Card className="bg-text-black p-3 gap-3 d-flex">
                    <Accordion
                        accordionItems={[
                            {
                                key: '0',
                                title: COMPANY_PARAMETERS,
                                isConfigurationRequired: false,
                                eventKey: '0',
                                children: (
                                    <div className="d-flex flex-column gap-4">
                                        <ToggleTextPanel
                                            header={DISABLE_NOTIFICATIONS}
                                            subText={DISABLE_NOTIFICATIONS_CAPTION}
                                            checked={enableBanner}
                                            onToggle={handleToggleNotificationMode}
                                        />
                                        <div>
                                            <Typography variant="body2" color="text-high-emphasis">
                                                {t('WELCOME_MESSAGE')}
                                            </Typography>
                                            <Typography variant="body3" color="text-medium-emphasis">
                                                {t('WELCOME_MESSAGE_SUB_TEXT')}
                                            </Typography>
                                            <div className="settings-page__dropdown-container">
                                                <DynamicDropdown
                                                    variant="default"
                                                    options={welcomeMessageDropdownOptions}
                                                    selectedOptions={selectedDelay}
                                                    onOptionSelected={handleDelayChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ),
                            },
                            {
                                key: '1',
                                title: USER_MANAGEMENT,
                                isConfigurationRequired: false,
                                eventKey: '1',
                                children: (
                                    <>
                                        <Table
                                            columns={shareSecurityPostureColumns()}
                                            rowsPerPageOptions={USER_TABLE_ROWS_PER_PAGE_OPTIONS}
                                            showSubHeader={true}
                                            tableBodyHeight={DATA_GRID_HEIGHT}
                                            subHeaderVariant="with-button"
                                            subHeaderContent={
                                                <InviteSubHeaderContent
                                                    onInputChange={handleInputChange}
                                                    emailInput={inputValue}
                                                    isValidEmail={isValidEmail}
                                                    dropdownValue={dropdownValue}
                                                    onDropdownChange={handleDropdownChange}
                                                    addUser={handleInviteUser}
                                                    invitedUserList={invitedList ?? []}
                                                    disableInviteButton={disableInvite}
                                                />
                                            }
                                            pagination={false}
                                            cacheKey="agents"
                                            transformer={passthrough}
                                            searchKey="email"
                                            refreshTableData={refreshData}
                                        />
                                        <ConfirmationModal
                                            modalTitle={'REMOVE USER'}
                                            modalContent={'Are you sure you want to remove this user?'}
                                            onPrimaryButtonClick={handleUserDeleteClick}
                                            primaryButtonLabel={YES_DELETE_IT}
                                            show={showConfirmationUserManagementModal}
                                            onClose={handleCloseUserManagementModal}
                                            buttonVariant="danger"
                                        />
                                    </>
                                ),
                            },
                            {
                                key: '2',
                                title: HEALTH_SCORE_CONFIGURATION,
                                isConfigurationRequired: false,
                                eventKey: '2',
                                children: (
                                    <>
                                        <ConfigurationTabs
                                            configTabs={[
                                                {
                                                    eventKey: HEALTH_SCORE_CONFIGURATION_TABS[0],
                                                    title: HEALTH_SCORE_CONFIGURATION_TABS[0],
                                                    content: <HealthScoreConfigurationTable />,
                                                },
                                                {
                                                    eventKey: HEALTH_SCORE_CONFIGURATION_TABS[1],
                                                    title: HEALTH_SCORE_CONFIGURATION_TABS[1],
                                                    content: <HighRiskCohortConfigurationTable />,
                                                },
                                            ]}
                                        />
                                    </>
                                ),
                            },
                        ]}
                        defaultActiveKey={DEFAULT_ACTIVE_KEYS}
                        alwaysOpen={true}
                    />
                </Card>
                <SettingConfirmationModal
                    content={modalContent()}
                    getAmpSettingValue={getAmpSettingValue}
                    savingSettings={savingSettings}
                    settingKey={settingKey!}
                    showConfirmationModal={showConfirmationModal}
                    title={modalTitle()}
                    onClose={handleModalClose}
                    onConfirm={handleConfirmModal}
                    onError={handleModalError}
                    onCallback={handleModalCallback}
                />
                <ConfirmationModal
                    modalTitle={'CHANGE ROLE'}
                    modalContent={'Are you sure you want to change the role of this user?'}
                    onPrimaryButtonClick={handleAccessChange}
                    primaryButtonLabel="CONFIRM"
                    show={showModal.accessUser}
                    onClose={handleCloseModal}
                    buttonVariant={'primary'}
                />
                <ConfirmationModal
                    modalTitle={'REMOVE USER'}
                    modalContent={'Are you sure you want to remove this user?'}
                    onPrimaryButtonClick={handleRemoveUser}
                    primaryButtonLabel="YES, REMOVE IT"
                    show={showModal.removeUser}
                    onClose={handleCloseModal}
                    buttonVariant={'danger'}
                />
            </div>
        );
    };
    return (
        <PageErrorHandlerAndLoaderWrapper
            error={settingsError}
            render={SettingsContent}
            isLoading={isLoading}
            handleRefresh={refreshTenantSettings}
        />
    );
};

export default SettingsPage;
