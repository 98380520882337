import { AIServiceModelName, SECURITY_FINDINGS, VIEW_QUERY_MODAL_TITLE } from 'Core-utils/constants/constants';
import { FindingsPrompt } from 'Components/molecules/FindingsPrompt';
import { Typography } from 'Components/atoms';
import { PromptType } from 'Core-utils/types';
import { useEffect, useState } from 'react';
import { createPrediction, getFindingsView } from 'Apis/library';
import { FilterCriteria, Page } from '@ampsec/platform-client';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
import { FindingsGrid } from 'Components/organisms';
import { ViewQueryModal } from 'Components/organisms/ViewQueryModal';
import { Dispatch, RootState } from 'Rdx/store';
import { useDispatch, useSelector } from 'react-redux';
import './styles.scss';
import useTimeSpent from 'Hooks/useTimeSpent';

const COMPONENT_IDENTIFIER = 'Security Findings Grid';

const SecurityFindingsPage = () => {
    useTimeSpent(COMPONENT_IDENTIFIER);
    const [prompt, setPrompt] = useState<PromptType>({} as PromptType);
    const [prompts, setPrompts] = useState<PromptType[]>([]);
    const [input, setInput] = useState<string>('');
    const [query, setQuery] = useState({});
    const [data, setData] = useState<Page<any> | undefined>(undefined);
    const [error, setError] = useState<string>();
    const [reset, setReset] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [show, setShow] = useState<boolean>(false);
    const location = useLocation().state;
    const [promptResponses, setPromptResponses] = useState<{ id: string; jsonQuery: Object }[]>([]);
    const [promptId, setPromptId] = useState<string>('');
    const dispatch = useDispatch<Dispatch>();
    const findingsModel = useSelector((state: RootState) => state.findingsModel);
    const handleSendButtonClick = () => {
        setLoading(true);
        const id = Math.random().toString(16).slice(2);
        setPrompt((prompt) => ({ ...prompt, value: input || '', id: id }));

        createPrediction(process.env.AI_MODEL_GPT4 as AIServiceModelName.GPT4, input).then((response: any) => {
            if (_.isEmpty(response?.query)) {
                setData({} as Page<any>);
                setLoading(false);
            } else {
                setQuery(response?.query);
                const updatedResponses = [...promptResponses, { id: id, jsonQuery: response.query }];
                setPromptResponses(updatedResponses);
                getFindingsView(response.query ?? {}, {})
                    .then((res: any) => {
                        setData(res);
                    })
                    .catch((error) => {
                        setError(error);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        });
        setInput('');
    };

    useEffect(() => {
        if (prompt && prompt.value) {
            const promptIndex = prompts.findIndex((promptItem) => promptItem.id === prompt.id);
            const updatedPrompts = [...prompts];
            if (promptIndex !== -1) {
                updatedPrompts[promptIndex] = { ...prompt, disabled: loading, query: query };
            } else {
                updatedPrompts.push({ ...prompt, disabled: loading, query: query });
            }
            setPrompts(updatedPrompts);
            dispatch.findingsModel.setFindings(updatedPrompts);
        }
    }, [prompt, loading]);

    function extractConditions(queryString: any) {
        try {
            const parsedQuery = JSON.parse(queryString);
            const conditions = [] as any;

            extractConditionsRecursively(parsedQuery, conditions);

            return conditions;
        } catch (error) {
            return [];
        }
    }

    function extractConditionsRecursively(query: any, conditions: any) {
        if (typeof query === 'object' && query !== null) {
            if (query['$and'] && Array.isArray(query['$and'])) {
                query['$and'].forEach((condition) => {
                    extractConditionsRecursively(condition, conditions);
                });
            } else if (query['$or'] && Array.isArray(query['$or'])) {
                query['$or'].forEach((condition) => {
                    extractConditionsRecursively(condition, conditions);
                });
            } else {
                conditions.push(query);
            }
        }
    }

    extractConditions(query);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInput(event.target.value);
    };

    const handleClearPrompt = () => {
        setPrompts([]);
        dispatch.findingsModel.setFindings([]);
        setReset(true);
        setQuery({});
        setPromptResponses([]);
    };

    const handleViewQueryClick = (promptId: string) => {
        setPromptId(promptId);
        setShow(true);
    };

    const handleCloseModal = () => {
        setShow(false);
    };

    const lastIndex = findingsModel.findings.length - 1;
    const defaultFilter = { ['finding_status']: 'OPEN', ...location };

    return (
        <div className="d-flex flex-column gap-3 pt-4 h-100 security-findings">
            <Typography variant="body11" color="text-high-emphasis">
                {SECURITY_FINDINGS}
            </Typography>
            <FindingsPrompt
                onSendButtonClick={handleSendButtonClick}
                onInputChange={handleInputChange}
                prompt={prompt}
                input={input}
                setInput={setInput}
                onClearPrompt={handleClearPrompt}
                prompts={(findingsModel.findings as PromptType[]) ?? []}
                loading={loading}
                onViewQueryClick={handleViewQueryClick}
            />
            <FindingsGrid
                enableUpdatingURLWithFiltersAndSorting={true}
                showSubHeader={false}
                filter={findingsModel.findings[lastIndex]?.query as FilterCriteria}
                data={data}
                dataIsLoading={loading}
                reset={reset}
                defaultFilter={defaultFilter}
                error={error}
            />
            <ViewQueryModal
                onClose={handleCloseModal}
                show={show}
                json={JSON.parse(
                    JSON.stringify(
                        promptResponses.find((responseItem) => responseItem.id === promptId)?.jsonQuery ?? {},
                    ),
                )}
                isFooter={false}
                title={VIEW_QUERY_MODAL_TITLE}
            />
        </div>
    );
};

export default SecurityFindingsPage;
