import { Button } from 'react-bootstrap';
import { IoArrowBackOutline } from '@react-icons/all-files/io5/IoArrowBackOutline';
import { BACK } from 'Core-utils/constants/constants';
import { Typography } from 'Components/atoms';
import { useNavigate } from 'react-router-dom';
import './styles.scss';

interface BackButtonProps {
    navigateBack?: string;
}

const BackButton = ({ navigateBack }: BackButtonProps) => {
    const navigate = useNavigate();
    const handleBackClick = () => {
        if (navigateBack) {
            navigate(navigateBack);
        } else {
            history.back();
        }
    };

    return (
        <div className="back-button align-content-center">
            <Button
                variant="outline-primary"
                className="back-button__button d-flex align-items-center justify-content-center text-text-high-emphasis"
                onClick={handleBackClick}
                data-testid="back-button"
            >
                <IoArrowBackOutline />
                <Typography variant="body2">{BACK}</Typography>
            </Button>
        </div>
    );
};

export default BackButton;
