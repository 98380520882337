import _ from 'lodash';
import { TableColumn } from 'react-data-table-component';
import { Typography } from 'Components/atoms';
import { Table } from 'Components/organisms';
import { AutoCompleteItem, MenuItem } from 'Core-utils/types/types';
import {
    ADD_COHORT,
    CONFIGURATION_HIGH_RISK_COHORT_LABELS,
    CUSTOM_SCORE_VALUE_MODAL_COHORT,
    DELETE_CUSTOM_SCORE_COHORT,
    HEALTH_SCORE_CONFIGURATION_PLACEHOLDER,
    USER_TABLE_ROWS_PER_PAGE_OPTIONS,
    YES_DELETE_IT,
} from 'Core-utils/constants/constants';
import { RiDeleteBin6Line } from '@react-icons/all-files/ri/RiDeleteBin6Line';
import { DropdownComponent } from 'Components/molecules';
import { useEffect, useMemo, useState } from 'react';
import { generateHealthScoreConfigurationFactors } from 'Core-utils/helpers/helpers';
import { AutoCompleteDropdown } from 'Components/molecules/AutoCompleteDropdown';
import { Button } from 'react-bootstrap';
import { CohortDto, CohortKind, CustomScoreCohortDto, KIND } from '@ampsec/platform-client';
import {
    createHighRiskCohorts,
    deleteHighRiskCohort,
    getCohorts,
    getHighRiskCohorts,
    updateHighRiskCohorts,
} from 'Apis/library';
import { useApiObjectData } from 'Hooks/useApiObjectData';
import { ConfirmationModal } from 'Components/molecules';
import classNames from 'classnames';
import { useI18n } from 'Hooks/useI18n';
import { ToastMessage } from 'Components/molecules';
import { useConnectorData } from 'Hooks/useConnectorsData';
import './styles.scss';

const HighRiskCohortConfigurationTable = () => {
    const { data: fetchCohorts, refresh } = useApiObjectData<CustomScoreCohortDto[], CustomScoreCohortDto[]>(
        KIND.CUSTOM_SCORE_COHORTS,
        getHighRiskCohorts,
        (a) => a,
        {
            'sort[multiplier]': 'DESC',
        },
    );
    const { connectorsByCategoryGroup } = useConnectorData();
    const [idToDelete, setIdToDelete] = useState<string>('');
    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
    const [healthScoreData, setHealthScoreData] = useState<CustomScoreCohortDto[]>([]);
    const [filteredData, setFilteredData] = useState<MenuItem[]>([]);
    const [rowExpanded, setRowExpanded] = useState<{ [key: string]: boolean }>({});
    const [refreshData, setRefreshData] = useState<boolean>(false);
    const { t } = useI18n();

    const extractDisabledItems = (healthScoreData: CustomScoreCohortDto[]) => {
        return Object.values(healthScoreData).flatMap(extractValues);
    };

    const extractValues = (item: CustomScoreCohortDto) => {
        return item.cohort?.flatMap((selectedValue) => selectedValue.id) || [];
    };

    const globalDisabledItems = useMemo(() => {
        return extractDisabledItems(healthScoreData);
    }, [healthScoreData]);

    const handleRow = () => {
        const genId = Math.floor(10000000 + Math.random() * 90000000)
            .toString(16)
            .slice(2);
        setHealthScoreData((item: any) => [...item, { id: genId, cohort: [], multiplier: 1 }]);
    };
    const isButtonDisabled =
        healthScoreData.slice(-1)[0]?.cohort?.length === 0 ||
        (filteredData.length !== 0 && filteredData.length === globalDisabledItems.length);

    const passthrough = (a: any) => a;

    const handleDelete = (row: CustomScoreCohortDto, e: React.MouseEvent<SVGElement, MouseEvent>) => {
        e.preventDefault();
        setShowConfirmationModal(true);
        setIdToDelete(row?.id);
    };

    const handleChangeSelectedValues = (rowId: string) => {
        setRowExpanded((prevRowExpanded) => ({
            ...prevRowExpanded,
            [rowId]: !prevRowExpanded[rowId],
        }));
    };
    const handleDeleteClick = () => {
        deleteHighRiskCohort(idToDelete).then(() => setRefreshData((refreshData) => !refreshData));
        setShowConfirmationModal(false);
    };

    const handleCloseModal = () => {
        setShowConfirmationModal(false);
    };

    useEffect(() => {
        const handleOutsideClick = (e: MouseEvent) => {
            const isRowExpanded = (id: any) =>
                rowExpanded[id] && !e.composedPath().includes(document.getElementById(`row-${id}`) as EventTarget);

            if (Object.keys(rowExpanded).some(isRowExpanded)) {
                setRowExpanded((prevRowExpanded) => {
                    const newRowExpanded = { ...prevRowExpanded };
                    Object.keys(newRowExpanded).forEach((id: any) => (newRowExpanded[id] = false));
                    return newRowExpanded;
                });
            }
        };

        window.addEventListener('click', handleOutsideClick);

        return () => {
            window.removeEventListener('click', handleOutsideClick);
        };
    }, [rowExpanded]);

    useEffect(() => {
        if (Array.isArray(fetchCohorts)) {
            setHealthScoreData(fetchCohorts);
        }
    }, [fetchCohorts]);

    useEffect(() => {
        refresh();
    }, [refreshData]);

    const HealthScoreConfigurationColumns: TableColumn<CustomScoreCohortDto>[] = [
        {
            id: 'usercohort',
            name: <Typography variant="body2">{CONFIGURATION_HIGH_RISK_COHORT_LABELS[0]}</Typography>,
            sortable: false,
            cell: (row: CustomScoreCohortDto) => {
                const [searchText, setSearchText] = useState<string>('');
                const { data: fetchCohortsData, isLoading } = useApiObjectData<CohortDto[], MenuItem[]>(
                    searchText ?? '',
                    getCohorts,
                    (dataItem) => {
                        return (
                            dataItem &&
                            dataItem.map((item) => ({
                                id: item.value,
                                displayValue: item.displayValue,
                                value: item.value,
                                kind: item.kind,
                            }))
                        );
                    },
                );

                const cohortsArray = (items: MenuItem[]) =>
                    items.map((item) => ({
                        ...item,
                        inclusive: item.inclusive ?? true,
                        value: item.value ?? '',
                        displayValue: item.displayValue ?? '',
                        kind: item.kind as CohortKind,
                        id: item.id ?? '',
                    })) ?? [];

                const handleAutoCompleteChange = (selectedItems: AutoCompleteItem[]) => {
                    const data = healthScoreData.find((item) => item.id === row.id);
                    if (data?.id) {
                        setRowExpanded((prevRowExpanded) => ({
                            ...prevRowExpanded,
                            [data?.id]: true,
                        }));
                    }

                    const items = filteredData.filter((item) =>
                        selectedItems.some((selectedItem) => selectedItem.id === item.id),
                    );

                    setHealthScoreData((prevData) => {
                        const updatedOptions = [...prevData];
                        const existingItemIndex = updatedOptions.findIndex((dataItem) => dataItem.id === data?.id);
                        if (existingItemIndex !== -1) {
                            updatedOptions[existingItemIndex] = {
                                ...updatedOptions[existingItemIndex],
                                cohort: _.uniqBy(
                                    [...updatedOptions[existingItemIndex].cohort, ...cohortsArray(items)],
                                    'id',
                                ),
                            };
                        } else {
                            updatedOptions.push({
                                ...(data as CustomScoreCohortDto),
                                cohort: [...row.cohort, ...cohortsArray(items)],
                            });
                        }
                        return updatedOptions;
                    });

                    setSearchText('');
                };

                const handleChipClose = (closedItem: string) => {
                    row.cohort = row.cohort?.filter((rowData: any) => rowData.id !== closedItem) ?? [];
                    const updateCohort = (updatedDataItem: CustomScoreCohortDto) => {
                        if (updatedDataItem.id === row.id) {
                            return {
                                ...updatedDataItem,
                                cohort: updatedDataItem.cohort.filter((rowData) => rowData.id !== closedItem) ?? [],
                            };
                        }
                        return updatedDataItem;
                    };
                    setHealthScoreData((prevData) => prevData.map(updateCohort));
                };

                const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
                    const newSearchText = e.target.value;
                    setSearchText(newSearchText);
                };

                const handleRowExpansion = () => {
                    if (!_.isEmpty(rowExpanded) && !rowExpanded[row.id]) {
                        const existingCohort = fetchCohorts.find((item) => item.id === row.id);
                        const healthScoreItem = healthScoreData.find((item) => item.id === row.id);
                        if (healthScoreItem && !_.isEmpty(healthScoreItem.cohort)) {
                            if (existingCohort) {
                                if (!_.isEqual(existingCohort.cohort, healthScoreItem.cohort)) {
                                    updateHighRiskCohorts({
                                        id: existingCohort.id,
                                        cohort: healthScoreItem.cohort,
                                        multiplier: healthScoreItem.multiplier || 1,
                                    }).then(() => refresh());
                                }
                            } else {
                                createHighRiskCohorts({
                                    cohort: healthScoreItem.cohort,
                                    multiplier: 1,
                                }).then(() => refresh());
                            }
                            setSearchText('');
                        }
                    }
                };

                useEffect(() => {
                    setFilteredData(Object.values(fetchCohortsData));
                }, [fetchCohortsData, searchText, isLoading]);

                useEffect(() => {
                    handleRowExpansion();
                }, [rowExpanded, row.id, healthScoreData]);

                const selectedOptions = healthScoreData
                    .find((dataItem) => dataItem.id === row?.id)
                    ?.cohort.map((data) => ({
                        ...data,
                        icon: connectorsByCategoryGroup?.['Identity & Access'][0]?.imageUrl,
                    }));

                return (
                    <AutoCompleteDropdown
                        dropDownItems={
                            filteredData &&
                            filteredData.map((data) => ({
                                ...data,
                                icon: connectorsByCategoryGroup?.['Identity & Access']?.[0]?.imageUrl,
                            }))
                        }
                        onSelectedItemsChange={handleAutoCompleteChange}
                        disabledItems={globalDisabledItems}
                        onCloseChip={handleChipClose}
                        selectedItems={selectedOptions ?? []}
                        rowIndex={row.id}
                        isExpanded={rowExpanded[row.id]}
                        onChangeSelectedValues={() => handleChangeSelectedValues(row.id)}
                        placeholder={HEALTH_SCORE_CONFIGURATION_PLACEHOLDER[1]}
                        onSearchChange={handleSearchChange}
                        searchQuery={searchText}
                    />
                );
            },
        },
        {
            id: 'multiplier',
            name: <Typography variant="body2">{CONFIGURATION_HIGH_RISK_COHORT_LABELS[1]}</Typography>,
            sortable: false,
            wrap: true,
            width: '10.25rem',
            cell: (row: CustomScoreCohortDto) => {
                const handleChange = (multiplier: MenuItem) => {
                    setHealthScoreData((prevData: any) => {
                        return prevData.map((item: any) =>
                            item.id === row.id ? { ...item, multiplier: Number(multiplier?.value.slice(0, -2)) } : item,
                        );
                    });
                    const itemToUpdate = fetchCohorts?.find((dataItem) => dataItem?.id === row?.id);

                    if (itemToUpdate?.id) {
                        updateHighRiskCohorts({
                            id: healthScoreData.find((item) => item.id === row.id)?.id ?? '',
                            cohort:
                                healthScoreData
                                    .find((item) => item.id === row.id)
                                    ?.cohort?.map((item) => ({
                                        ...item,
                                        inclusive: item.inclusive ?? true,
                                        value: item.value ?? '',
                                        displayValue: item.displayValue ?? '',
                                        kind: item.kind as CohortKind,
                                        id: item.id ?? '',
                                    })) ?? [],
                            multiplier: Number(multiplier?.value.slice(0, -2)),
                        }).then(() => setRefreshData((refreshData) => !refreshData));
                    } else {
                        createHighRiskCohorts({
                            id: row?.id ?? '',
                            cohort: [],
                            multiplier: Number(multiplier?.value.slice(0, -2)),
                        }).then(() => setRefreshData((refreshData) => !refreshData));
                    }
                };

                return (
                    <DropdownComponent
                        disableToggle={!fetchCohorts?.find((dataItem) => dataItem?.id === row?.id)?.id}
                        value={`${row.multiplier} ${'x'}` || '1 x'}
                        dropDownItems={generateHealthScoreConfigurationFactors()}
                        onChange={(value) => handleChange({ id: 0, value: value ?? '' })}
                        isStartCase={false}
                    />
                );
            },
        },
        {
            id: 'delete',
            name: '',
            sortable: false,
            wrap: true,
            width: '4.25rem',
            cell: (row: CustomScoreCohortDto) => {
                const disableClassName = classNames({
                    'settings-page__disable-icon': _.isEmpty(row.cohort),
                });
                return (
                    <RiDeleteBin6Line
                        size={20}
                        className={`settings-page__icon ${disableClassName}`}
                        onClick={(e) => handleDelete(row, e)}
                        aria-disabled={true}
                    />
                );
            },
        },
    ];

    return (
        <div className="d-flex flex-column gap-2">
            <ToastMessage toastSubText={t('CUSTOM_SCORE_HELP')} variant="info" />
            <Table
                columns={HealthScoreConfigurationColumns}
                rowsPerPageOptions={USER_TABLE_ROWS_PER_PAGE_OPTIONS}
                showSubHeader={false}
                data={healthScoreData}
                subHeaderVariant="with-button"
                pagination={false}
                cacheKey="users"
                transformer={passthrough}
                tableBodyHeight="21.5rem"
                refreshTableData={refreshData}
            />
            <Button
                variant="body5 border-0 text-text-interactive d-flex justify-content-start align-items-center p-0 mt-2"
                onClick={handleRow}
                disabled={isButtonDisabled}
            >
                {ADD_COHORT}
            </Button>
            <ConfirmationModal
                modalTitle={DELETE_CUSTOM_SCORE_COHORT}
                modalContent={CUSTOM_SCORE_VALUE_MODAL_COHORT}
                onPrimaryButtonClick={handleDeleteClick}
                primaryButtonLabel={YES_DELETE_IT}
                show={showConfirmationModal}
                onClose={handleCloseModal}
                buttonVariant="danger"
            />
        </div>
    );
};

export default HighRiskCohortConfigurationTable;
