import { Typography } from 'Components/atoms';
import { DataProp } from 'Core-utils/types/types';
import ErrorHandlerAndLoaderWrapper from 'Wrappers/ErrorHandlerandLoaderWrapper';
import React, { useState } from 'react';
import _ from 'lodash';
import { CgArrowsExpandRight } from '@react-icons/all-files/cg/CgArrowsExpandRight';
import { FindingsGridModal } from '../FindingsGridModal';
import { EMPLOYEE, departmentFilterMap, filterMap, securityFindingsColumnMap } from 'Core-utils/constants/constants';
import { updateURLWithFiltersAndSorting } from 'Core-utils/helpers/helpers';
import './styles.scss';

interface CardMetricsProps {
    title: string;
    metrics: {
        metric: DataProp[];
        error?: any;
        isLoading?: boolean;
        refresh?: React.MouseEventHandler<HTMLButtonElement>;
        imageSrc?: string;
        isEmpty?: boolean;
    };
}

export interface DepartmentDataProps {
    data: CardMetricsProps[];
    departmentFilter?: string;
}

const getDefaultFilters = (department: string, selectedDepartment: string) => {
    const defaultFilter =
        selectedDepartment === 'ALL'
            ? filterMap[departmentFilterMap[department]]
            : filterMap[departmentFilterMap[department]] && {
                  ...filterMap[departmentFilterMap[department]],
                  [securityFindingsColumnMap.userDepartment]: [selectedDepartment],
              };
    return defaultFilter;
};

const CardMetrics = ({ metrics }: CardMetricsProps) => {
    const metricsArray = Array.isArray(metrics.metric) ? metrics.metric : [];

    const renderMetrics = () => {
        return (
            <div className="d-flex justify-content-between align-items-center ">
                <div className="d-flex flex-row gap-4 justify-content-between align-items-center">
                    {metricsArray.map((metric: DataProp) => (
                        <div
                            key={`metric-${metric.label}-${metric.value}`}
                            className="dept-card__typography-content d-flex flex-column align-items-center justify-content-between"
                        >
                            <Typography
                                variant={'subtitle3'}
                                color={metric.labelColor}
                                style={{
                                    maxWidth: '10rem',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                {metric.label}
                            </Typography>
                            <Typography variant={'subtitle5'} color={metric.valueColor}>
                                {metric.value}
                            </Typography>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    return (
        <ErrorHandlerAndLoaderWrapper
            error={metrics.error}
            isLoading={metrics.isLoading}
            handleRefresh={metrics.refresh}
            render={renderMetrics}
            isEmpty={metrics.isEmpty ?? false}
            ImageSrc={metrics.imageSrc ?? ''}
            noDescription={true}
        />
    );
};

const CardContent = ({ data, departmentFilter }: DepartmentDataProps) => {
    const [showModal, setShowModal] = useState<boolean[]>(Array(data.length).fill(false));

    const handleClose = (index: number) => () => {
        updateURLWithFiltersAndSorting({});
        const updatedShowModal = [...showModal];
        updatedShowModal[index] = false;
        setShowModal(updatedShowModal);
    };

    const handleClick = (index: number) => () => {
        const updatedShowModal = [...showModal];
        updatedShowModal[index] = !updatedShowModal[index];
        setShowModal(updatedShowModal);
    };

    return (
        <div className="d-flex justify-content-between gap-3 card-content" style={{ width: '95%' }}>
            {data.map((departmentData, index) => (
                <React.Fragment key={index}>
                    <div className="d-flex flex-column justify-content-between gap-3">
                        <div className="d-flex flex-row justify-content-center gap-4">
                            <Typography variant={'body8'}>{departmentData.title}</Typography>
                        </div>
                        <div className="d-flex">
                            <CardMetrics title={departmentData.title} metrics={departmentData.metrics} />
                        </div>
                    </div>
                    {departmentData.title !== EMPLOYEE && (
                        <CgArrowsExpandRight
                            size={22}
                            onClick={handleClick(index)}
                            className="text-text-low-emphasis card-content__expand-icon"
                            data-testid="expand-icon"
                        />
                    )}
                    {index < data.length - 1 && <div className="vr mb-1" />}
                    <FindingsGridModal
                        title={departmentData.title}
                        onClose={handleClose(index)}
                        show={showModal[index]}
                        defaultFilter={getDefaultFilters(departmentData.title, departmentFilter ?? '')}
                    />
                </React.Fragment>
            ))}
        </div>
    );
};

export default CardContent;
