import './styles.scss';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { TfiClose } from '@react-icons/all-files/tfi/TfiClose';
import {
    CANCEL,
    CREATE,
    CREATE_ENGAGEMENT,
    ENGAGEMENT_DESCRIPTION,
    ENGAGEMENT_TITLE,
    ENGAGEMENT_TITLE_VALIDATION_TEXT,
} from 'Core-utils/constants/constants';
import { DEFAULT_WORKFLOW_ROUTE } from 'Core-utils/routes';
import { createFlow } from 'Apis/library';

const DESCRIPTION_CHAR_LIMIT = 500;

type CreateEngagementModalProps = {
    show: boolean;
    onClose: () => void;
    defaultTitle?: string;
    defaultDescription?: string;
};

const CreateEngagementModal = ({ show, onClose, defaultTitle, defaultDescription }: CreateEngagementModalProps) => {
    const navigate = useNavigate();
    const [validated, setValidated] = useState(false);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');

    const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(event.target.value);
    };
    const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDescription(event.target.value);
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
            return;
        }

        setValidated(false);
        createFlow(title, description).then((response) =>
            navigate(`${DEFAULT_WORKFLOW_ROUTE}/create-engagement/${response?.id}`),
        );

        setTitle('');
        setDescription('');
        onClose();
    };

    const onCancelHandler = () => {
        setValidated(false);
        setTitle('');
        setDescription('');
        onClose();
    };

    useEffect(() => {
        setTitle(defaultTitle ?? '');
        setDescription(defaultDescription ?? '');
    }, [defaultTitle, defaultDescription]);
    return (
        <Modal show={show} onHide={onCancelHandler} centered size="lg" className="create-engagement-modal">
            <Modal.Header className="create-engagement-modal__header bg-body-color text-text-high-emphasis border border-structural-stroke-100 rounded-top">
                <Modal.Title className="body11 p-1">{CREATE_ENGAGEMENT}</Modal.Title>
                <TfiClose
                    className="text-text-high-emphasis btn-close position-absolute end-0 me-2"
                    onClick={onClose}
                    size={10}
                />
            </Modal.Header>
            <Modal.Body className="create-engagement-modal__body bg-body-color text-text-high-emphasis rounded-bottom border border-structural-stroke-100 border-top-0">
                <Form noValidate validated={validated} onSubmit={handleSubmit} data-testid="form-submit">
                    <Form.Group className="mb-3">
                        <Form.Label className="body5">{ENGAGEMENT_TITLE}</Form.Label>
                        <Form.Control
                            required
                            type="input"
                            className="bg-transparent text-text-high-emphasis border border-structural-stroke-100"
                            onChange={handleTitleChange}
                            autoFocus
                            value={title}
                            data-testid="engagementTitle"
                        />
                        <Form.Control.Feedback type="invalid">{ENGAGEMENT_TITLE_VALIDATION_TEXT}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <div className="create-engagement-modal__body__textarea-wrapper">
                            <Form.Label className="body5">{ENGAGEMENT_DESCRIPTION}</Form.Label>
                            <Form.Control
                                data-testid="engagementDescription"
                                as="textarea"
                                className="create-engagement-modal__body__textarea-wrapper__textarea bg-transparent text-text-high-emphasis border border-structural-stroke-100 custom-scrollbar"
                                rows={3}
                                maxLength={DESCRIPTION_CHAR_LIMIT}
                                onChange={handleDescriptionChange}
                                value={description}
                            />
                            <div className="create-engagement-modal__body__textarea-wrapper__count caption4 end-0 bottom-0 pe-2 pb-1 text-text-low-emphasis">
                                {description.length} / {DESCRIPTION_CHAR_LIMIT}
                            </div>
                        </div>
                    </Form.Group>
                    <div className="create-engagement-modal__actions d-flex gap-3 justify-content-center mt-">
                        <Button
                            variant="outline-text-low-emphasis"
                            className="body1 btn-cancel"
                            onClick={onCancelHandler}
                        >
                            {CANCEL}
                        </Button>
                        <Button variant="primary-500" className="body1 text-text-black" type="submit">
                            {CREATE}
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default CreateEngagementModal;
