import classNames from 'classnames';
import './styles.scss';
import { FaX } from '@react-icons/all-files/fa6/FaX';
import { FaCheck } from '@react-icons/all-files/fa6/FaCheck';
import { ActiveEngagement } from '@ampsec/platform-client';
import { ToolingCoverageTooltip } from 'Components/molecules/ToolingCoverageTooltip';
import moment from 'moment';
import { ACTIVE_ENGAGEMENT_CHIP_TOOLTIP } from 'Core-utils/constants/constants';
import { PropsWithChildren, useMemo } from 'react';

type ActiveEngagementChipProps = Pick<
    ActiveEngagement,
    'scheduledDate' | 'expirationAt' | 'engagementStartedAt' | 'numberOfReminders' | 'flowName' | 'status'
>;

export type EngagementChipProps =
    | {
          activeEngagement: ActiveEngagementChipProps;
          variant: 'active';
      }
    | {
          variant: 'closed';
      };

const EngagementChipTooltip = ({
    numberOfReminders,
    scheduledDate,
    expirationAt,
    engagementStartedAt,
    flowName,
    status,
    children,
}: ActiveEngagementChipProps & PropsWithChildren) => {
    return (
        <ToolingCoverageTooltip
            headerLabel={flowName}
            tooltipContent={[
                {
                    key: ACTIVE_ENGAGEMENT_CHIP_TOOLTIP.SCHEDULED_DATE,
                    value: scheduledDate ?? '-',
                },
                {
                    key: ACTIVE_ENGAGEMENT_CHIP_TOOLTIP.NO_OF_REMINDERS,
                    value: `${numberOfReminders}`,
                },
                {
                    key: ACTIVE_ENGAGEMENT_CHIP_TOOLTIP.OPEN_FOR,
                    value: engagementStartedAt ? moment(engagementStartedAt).fromNow(true) : '-',
                },
                {
                    key: ACTIVE_ENGAGEMENT_CHIP_TOOLTIP.EXPIRATION_DATE,
                    value: expirationAt ? moment(expirationAt).fromNow() : '-',
                },
                {
                    key: ACTIVE_ENGAGEMENT_CHIP_TOOLTIP.MODE,
                    value: status ?? '-',
                },
            ]}
        >
            {children}
        </ToolingCoverageTooltip>
    );
};

const EngagementChip = (props: EngagementChipProps) => {
    const defaultChipClassName = `engagement-chip rounded-circle d-flex align-items-center justify-content-center text-text-black body2`;

    if (props.variant === 'closed') {
        return <div className={`${defaultChipClassName} bg-text-low-emphasis`}>{<FaCheck />}</div>;
    }

    const { expirationAt } = props.activeEngagement;
    const numberOfReminders = useMemo(() => props.activeEngagement.numberOfReminders + 1, [props.activeEngagement]);

    const isExpired = expirationAt && new Date(expirationAt) < new Date();

    const engagementChipClassName = classNames(defaultChipClassName, {
        'bg-secondary-accent-red-4': isExpired,
        'bg-vizualization-green-100': !isExpired && numberOfReminders < 3,
        'bg-secondary-accent-orange-3': !isExpired && numberOfReminders >= 3,
    });

    if (expirationAt && new Date(expirationAt) < new Date()) {
        return (
            <EngagementChipTooltip {...props.activeEngagement} numberOfReminders={numberOfReminders}>
                <div className={engagementChipClassName}>
                    <FaX />
                </div>
            </EngagementChipTooltip>
        );
    }
    return (
        <EngagementChipTooltip {...props.activeEngagement} numberOfReminders={numberOfReminders}>
            <div className={engagementChipClassName}>{numberOfReminders}</div>
        </EngagementChipTooltip>
    );
};

export default EngagementChip;
