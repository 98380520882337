import { nanoid } from 'nanoid';
import { Checkbox } from 'Components/atoms';
import { Typography } from 'Components/atoms';
import { FilterComponentProps } from 'Core-utils/types/types';
import './styles.scss';

function CheckboxList<T>({
    options,
    handleCheckbox,
    selectedOptions,
    header = true,
    customDisplayOptions,
}: FilterComponentProps<T>) {
    return (
        <>
            {header && (
                <div className="dropdown-header">
                    <Typography variant="body5" color="text-high-emphasis">
                        Filter
                    </Typography>
                </div>
            )}
            <form className="checkbox-list overflow-auto custom-scrollbar">
                {options.map((option) => (
                    <Checkbox
                        labelProp={option as string}
                        key={`checkbox-list-${nanoid()}`}
                        handleCheckbox={handleCheckbox}
                        checked={selectedOptions.includes(option)}
                        customDisplayLabel={customDisplayOptions?.[option as string]}
                    />
                ))}
            </form>
        </>
    );
}

export default CheckboxList;
