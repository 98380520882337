import { useEffect, useState } from 'react';
import { getColor, roundOffValue } from '../helpers/helpers';
import { Chart, ChartOptions, registerables } from 'chart.js';
import { EMPTY_LABELS } from './constants';
import { Dataset } from '../types/types';
import _ from 'lodash';
import { AreaGraphProps } from 'Components/molecules/AreaGraph/AreaGraph';
import { LineGraphProps } from 'Components/molecules/LineGraph/LineGraph';
import { UserLineGraphProps } from 'Components/molecules/UserLineGraph/UserLineGraph';

const calculateMinYaxisValue = (dataPoints: (number | null)[]) => {
    const compactDataPoints = _.compact(dataPoints);
    if (compactDataPoints.length === 0) {
        return 0;
    }
    const minValue = Math.min(...compactDataPoints);
    const minYaxisValue = Math.floor(minValue / 10) * 10 < 0 ? 0 : Math.floor(minValue / 10) * 10;
    return minYaxisValue;
};

const calculateMaxYaxisValue = (dataPoints: (number | null)[]) => {
    const compactDataPoints = _.compact(dataPoints);
    if (compactDataPoints.length === 0) {
        return 0;
    }
    const maxValue = Math.max(...compactDataPoints);
    const maxYaxisValue = Math.floor((maxValue + 10) / 10) * 10 || 0;
    return maxYaxisValue;
};

export const lineGraphData = (props: LineGraphProps) => {
    const [color, setColor] = useState('');
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const categories: string[] = [];
    const data = props.series.map((val: number) => (val > 0 ? val : null));

    if (props.categories) {
        categories.push(...props.categories);
    } else {
        for (let i = currentMonth - 5; i <= currentMonth; i++) {
            const monthIndex = i >= 0 ? i : 12 + i;
            categories.push(months[monthIndex]);
        }
    }

    return {
        series: [
            {
                data: data,
            },
        ],
        options: {
            chart: {
                height: 350,
                toolbar: {
                    show: false,
                },
            },
            dataLabels: {
                enabled: props.showDataLabel ?? true,
                style: {
                    colors: [color],
                    width: '8.75%',
                    height: '13%',
                    fontSize: '15px',
                },
                background: {
                    enabled: true,
                    foreColor: '#000000',
                },
                formatter: function (val: number, { dataPointIndex, w }: any) {
                    const value = w.config.series[0].data[dataPointIndex];

                    setColor(getColor(value));

                    if (dataPointIndex === w.config.series[0].data.length - 1) {
                        w.config.dataLabels.style.colors = [color];
                        return roundOffValue(val);
                    }
                    return '';
                },
            },
            xaxis: {
                categories: categories.map((str) => str.toUpperCase()),
                labels: {
                    style: {
                        fontFamily: 'Barlow',
                        fontSize: '10px',
                        fontWeight: 700,
                        lineHeight: '14px',
                        colors: '#FFFFFF',
                    },
                },
                tooltip: {
                    enabled: false,
                },
            },
            yaxis: {
                min: calculateMinYaxisValue(data),
                max: calculateMaxYaxisValue(data),
                tickAmount: 4,
                forceNiceScale: true,
                labels: {
                    style: {
                        fontFamily: 'Barlow',
                        fontSize: '10px',
                        fontWeight: 700,
                        lineHeight: '14px',
                        colors: ['#FFFFFF'],
                    },
                },
            },
            legend: {
                show: false,
            },
            colors: ['#9975FF'],
            stroke: {
                width: [3],
            },
            grid: {
                yaxis: {
                    lines: {
                        show: false,
                    },
                },
            },
            tooltip: {
                enabled: true,
                y: {
                    formatter: function (val: number, { dataPointIndex }: any) {
                        const index = dataPointIndex >= 0 ? dataPointIndex : categories.length + dataPointIndex;
                        return `${categories[index]} Score (${val})`;
                    },
                },
                x: {
                    show: false,
                },
                marker: {
                    show: false,
                },
            },
        },
    };
};

export const UserLineGraphData = (props: UserLineGraphProps) => {
    const [color, setColor] = useState('');
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const categories: string[] = [];
    const seriesData = props.series.map((data) => ({
        name: data?.name,
        data: Array.isArray(data.data) ? data.data.map((val) => (val > 0 ? val : null)) : [],
    }));
    const allDataPoints = seriesData.reduce((acc: (number | null)[], seriesItem) => acc.concat(seriesItem.data), []);

    if (props.categories) {
        categories.push(...props.categories);
    } else {
        for (let i = currentMonth - 5; i <= currentMonth; i++) {
            const monthIndex = i >= 0 ? i : 12 + i;
            categories.push(months[monthIndex]);
        }
    }
    const lineColors = props.lineColors ?? ['#FFFFFF', '#9975FF', '#958FA3'];

    return {
        series: seriesData,
        options: {
            chart: {
                height: 350,
                toolbar: {
                    show: false,
                },
            },
            dataLabels: {
                enabled: props.showDataLabels ?? true,
                enabledOnSeries: [0],
                style: {
                    colors: [color],
                    width: '8.75%',
                    height: '13%',
                    fontSize: '15px',
                },
                background: {
                    enabled: true,
                    foreColor: '#000000',
                },
                formatter: function (val: number, { dataPointIndex, w }: any) {
                    const value = w.config.series[0].data[dataPointIndex];

                    setColor(getColor(value));

                    if (dataPointIndex === w.config.series[0].data.length - 1) {
                        w.config.dataLabels.style.colors = [color];
                        return roundOffValue(val);
                    }
                    return '';
                },
            },
            xaxis: {
                categories: categories.map((str) => str.toUpperCase()),
                labels: {
                    style: {
                        fontFamily: 'Barlow',
                        fontSize: '10px',
                        fontWeight: 700,
                        lineHeight: '14px',
                        colors: '#FFFFFF',
                    },
                },
                tooltip: {
                    enabled: false,
                },
                crosshairs: {
                    show: false,
                },
            },
            yaxis: {
                min: calculateMinYaxisValue(allDataPoints),
                max: calculateMaxYaxisValue(allDataPoints),
                tickAmount: 4,
                forceNiceScale: true,
                labels: {
                    style: {
                        fontFamily: 'Barlow',
                        fontSize: '10px',
                        fontWeight: 700,
                        lineHeight: '14px',
                        colors: ['#FFFFFF'],
                    },
                },
            },
            legend: {
                show: false,
            },
            colors: lineColors,
            stroke: {
                width: props.width ?? [4, 2, 2],
            },
            grid: {
                yaxis: {
                    lines: {
                        show: false,
                    },
                },
            },
            tooltip: {
                enabled: true,
                shared: false,
                y: {
                    formatter: function (val: number, { seriesIndex, dataPointIndex }: any) {
                        const seriesDataPoint = seriesData[seriesIndex].data[dataPointIndex];
                        const seriesName = seriesData[seriesIndex].name;
                        return `${seriesName} Score (${seriesDataPoint})`;
                    },
                },
                x: {
                    show: false,
                },
                marker: {
                    show: false,
                },
                onDatasetHover: {
                    highlightDataSeries: true,
                },
            },
            markers: {
                hover: {
                    size: 0,
                },
            },
        },
    };
};

Chart.register(...registerables);

export const useRadarChartEffect = (chartRef: React.RefObject<HTMLCanvasElement>, datasets: Dataset[]) => {
    useEffect(() => {
        let chart: Chart;

        if (chartRef.current) {
            const ctx = chartRef.current.getContext('2d');

            if (ctx) {
                const labels = EMPTY_LABELS;

                chart = new Chart(ctx, {
                    type: 'radar',
                    data: {
                        labels: labels,
                        datasets: datasets.map((dataset) => ({
                            data: dataset.data.slice(0, 4),
                            backgroundColor: dataset.backgroundColor,
                            borderColor: dataset.borderColor,
                            borderWidth: 1,
                        })),
                    },
                    options: {
                        plugins: {
                            legend: {
                                display: false,
                            },
                            tooltip: {
                                enabled: true,
                                callbacks: {
                                    label: (context: any) => {
                                        const dataName = datasets[context.datasetIndex as number].name;
                                        return ` ${dataName} `;
                                    },
                                },
                                backgroundColor: '#d1cae5',
                                bodyColor: '#000000',
                                intersect: false,
                                interaction: {
                                    mode: 'nearest',
                                },
                            },
                        },
                        scales: {
                            r: {
                                angleLines: {
                                    color: '#38373B',
                                    lineWidth: 1,
                                },
                                grid: {
                                    circular: true,
                                    color: '#38373B',
                                    lineWidth: 1,
                                    stepSize: 25,
                                },
                                ticks: {
                                    display: false,
                                    min: 0,
                                    max: 100,
                                    stepSize: 25,
                                },
                                suggestedMin: 0,
                                suggestedMax: 100,
                                startAngle: 45,
                            },
                        },
                        elements: {
                            point: {
                                radius: 0,
                            },
                        },
                    } as ChartOptions<'radar'>,
                });

                datasets.forEach((dataset, index) => {
                    chart.data.datasets[index].data = dataset.data;
                });

                chart.update();
            }
        }

        return () => {
            if (chart) {
                chart.destroy();
            }
        };
    }, [chartRef, datasets]);
};

export const areaGraphData = (props: AreaGraphProps) => {
    const data = props.series.flatMap((series) => series.data);
    return {
        series: props.series,
        options: {
            chart: {
                height: 350,
                toolbar: {
                    show: false,
                },
            },
            dataLabels: {
                enabled: false,
            },
            xaxis: {
                categories: props.categories,
                labels: {
                    style: {
                        fontFamily: 'Barlow',
                        fontSize: '10px',
                        fontWeight: 700,
                        lineHeight: '14px',
                        colors: '#FFFFFF',
                    },
                },
            },
            yaxis: {
                min: calculateMinYaxisValue(data),
                max: calculateMaxYaxisValue(data),
                tickAmount: 4,
                labels: {
                    style: {
                        fontFamily: 'Barlow',
                        fontSize: '10px',
                        fontWeight: 700,
                        lineHeight: '14px',
                        colors: ['#FFFFFF'],
                    },
                },
            },
            legend: {
                show: false,
            },
            colors: ['#FF2629', '#FF8C22', '#FFFF07', '#65CC01'],
            stroke: {
                width: [2],
                curve: 'straight' as 'straight',
            },
            grid: {
                yaxis: {
                    lines: {
                        show: false,
                    },
                },
            },
            tooltip: {
                enabled: false,
            },
        },
    };
};
