import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { CgArrowsExpandRight } from '@react-icons/all-files/cg/CgArrowsExpandRight';
import { CustomCard } from 'Components/molecules';
import { HealthMetrics } from 'Components/molecules';
import { IconWithTypography } from 'Components/molecules';
import {
    filterMap,
    VULNERABILITIES,
    VulnerabilitiesLabelMap,
    VULNERABILITIES_LABELS,
} from 'Core-utils/constants/constants';
const { REPORTS } = require('@ampsec/platform-client');
import { getMetricsReportsData } from 'Apis/library';
import { Color } from 'Core-utils/types/color';
import { useApiObjectData } from '../../../hooks/useApiObjectData';
import ErrorHandlerAndLoaderWrapper from 'Wrappers/ErrorHandlerandLoaderWrapper';
import { FindingsGridModal } from '../FindingsGridModal';
import { BaseMetricResults } from 'Core-utils/types/types';
import vulnerabilityIcon from 'Assets/icons/vulnerability.svg';
import _ from 'lodash';
import { labelColorArr } from 'Core-utils/helpers/ReportsHelper';
import { updateURLWithFiltersAndSorting } from 'Core-utils/helpers/helpers';
import './styles.scss';

const COMPONENT_IDENTIFIER = 'Organization Out of SLA Vulnerabilities';

interface SLAData {
    label: string;
    value: number;
}

const Vulnerability = () => {
    const [show, setShow] = useState(false);

    const { data, error, isLoading, refresh } = useApiObjectData<BaseMetricResults, BaseMetricResults>(
        REPORTS.VULNERABILITY_SLA,
        getMetricsReportsData,
        (a) => a,
        { department: 'ALL' },
    );
    const buildVulnerabilityHealthMetric = (healthMetric: SLAData, index: number) => {
        const label = healthMetric?.value === null ? '-' : healthMetric?.value.toString();
        const valueColor: Color = labelColorArr[index];
        const value = VulnerabilitiesLabelMap[healthMetric?.label];
        const valueVariant: 'body1' | 'caption2' = 'body1';
        const labelColor: Color = 'text-high-emphasis';
        return { label, valueColor, value, valueVariant, labelColor };
    };
    const renderVulnerabilityRows = () => {
        const totalRows = 2;
        const totalCols = 2;

        return Array.from({ length: totalRows }).map((_, rowIndex) => (
            <Row key={rowIndex} className="vulnerabilities__vulnerability-row">
                {Array.from({ length: totalCols }).map((_, colIndex) => {
                    const index = rowIndex * totalCols + colIndex;
                    const key = VULNERABILITIES_LABELS[index];
                    const healthMetric = data?.[key];
                    const metrics = buildVulnerabilityHealthMetric(healthMetric, index);

                    return (
                        <Col key={colIndex} data-testid="sla-vulnerability-elements">
                            <HealthMetrics metric={metrics} />
                        </Col>
                    );
                })}
            </Row>
        ));
    };

    const VulnerabilityMetrics = () => {
        return <div className="container">{renderVulnerabilityRows()}</div>;
    };

    const handleClose = () => {
        updateURLWithFiltersAndSorting({});
        setShow(false);
    };
    const handleClick = () => {
        setShow(!show);
    };
    return (
        <div className="vulnerabilities h-100" data-testid="vulnerabilities">
            <CustomCard
                cardType="oneThird"
                title={
                    <>
                        <IconWithTypography isRow={true} label={VULNERABILITIES} labelColor="primary" />
                        <CgArrowsExpandRight className="vulnerabilities__icon" size={22} onClick={handleClick} />
                    </>
                }
                content={
                    <div className="vulnerabilities__error-container">
                        <ErrorHandlerAndLoaderWrapper
                            error={error}
                            isLoading={isLoading}
                            render={VulnerabilityMetrics}
                            handleRefresh={refresh}
                            isEmpty={_.isEmpty(data)}
                            ImageSrc={vulnerabilityIcon}
                            component={COMPONENT_IDENTIFIER}
                        />
                    </div>
                }
                onCardClick={handleClick}
            />
            <FindingsGridModal
                title={VULNERABILITIES}
                onClose={handleClose}
                show={show}
                defaultFilter={filterMap['Vulnerabilities']}
            />
        </div>
    );
};

export default Vulnerability;
