import { Button } from 'react-bootstrap';
import { Table } from 'Components/organisms';
import { FINDINGS_CONFIG_COLUMNS } from './FindingsConfigColumns';
import { useContext, useState } from 'react';
import FindingsModal from './FindingsModal';
import {
    ADD_FINDING,
    DEFAULT_ERROR_MSG,
    DELETE_FINDING,
    FINDING_MODAL_CONTENT,
    YES_DELETE_IT,
} from 'Core-utils/constants/constants';
import { ConfirmationModal } from 'Components/molecules';
import { FindingSpecDto, FindingSpecKind } from '@ampsec/platform-client';
import { addFindingSpecs, deleteFindingsById, updateFindings } from 'Apis/library';
import { ToastsContext } from 'Rdx/contexts/ToastsContext';

const FindingsConfigTable = () => {
    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
    const [idToDelete, setIdToDelete] = useState<string>('');
    const [show, setShow] = useState<boolean>(false);
    const [refreshData, setRefreshData] = useState<boolean>(false);
    const [findingData, setFindingData] = useState<FindingSpecDto>({} as FindingSpecDto);
    const { addToast } = useContext(ToastsContext);
    const TABLE_BODY_HEIGHT = '25rem';

    const handleAddFinding = () => {
        setShow((show) => !show);
    };
    const handleCloseModal = () => {
        setShow(false);
        setFindingData({} as FindingSpecDto);
    };
    const handleSave = async (finding: FindingSpecDto) => {
        if (finding.id) {
            await updateFindings({ ...finding, kind: FindingSpecKind.CUSTOM })
                .then(() => setRefreshData((refreshData) => !refreshData))
                .catch(() =>
                    addToast({
                        severity: 'error',
                        body: DEFAULT_ERROR_MSG,
                    }),
                );
        } else {
            await addFindingSpecs({ ...finding, kind: FindingSpecKind.CUSTOM }).then(() =>
                setRefreshData((refreshData) => !refreshData),
            );
        }
    };
    const handleDelete = (id: string) => {
        setShowConfirmationModal(true);
        setIdToDelete(id);
    };
    const handleNameClick = (row: FindingSpecDto) => {
        setShow(true);
        const tempFindingData = {
            id: row.id,
            name: row.name,
            eventType: row.eventType,
            severity: row.severity,
            displayValue: row.displayValue,
            description: row.description,
        };
        setFindingData(tempFindingData as FindingSpecDto);
    };

    const handleCloseConfirmationModal = () => {
        setShowConfirmationModal(false);
    };

    const handleDeleteClick = () => {
        deleteFindingsById(idToDelete)
            .then(() => setRefreshData((refreshData) => !refreshData))
            .catch(() =>
                addToast({
                    severity: 'error',
                    body: DEFAULT_ERROR_MSG,
                }),
            );
        setShowConfirmationModal(false);
    };

    const subHeaderContent = () => {
        return (
            <Button className="bg-primary-500 body1 ps-4 pe-4 pt-2 pb-2" onClick={handleAddFinding}>
                {ADD_FINDING}
            </Button>
        );
    };
    return (
        <>
            <Table
                cacheKey={'findingSpecs'}
                columns={FINDINGS_CONFIG_COLUMNS(handleDelete, handleNameClick)}
                rowsPerPageOptions={[10, 20, 30, 40]}
                transformer={(a) => a}
                subHeaderVariant="with-button"
                subHeaderContent={subHeaderContent()}
                placeHolder="Search..."
                tableBodyHeight={TABLE_BODY_HEIGHT}
                refreshTableData={refreshData}
                searchKey="displayValue"
                filter={{ cid: null }}
            />
            <ConfirmationModal
                modalTitle={DELETE_FINDING}
                modalContent={FINDING_MODAL_CONTENT}
                onPrimaryButtonClick={handleDeleteClick}
                primaryButtonLabel={YES_DELETE_IT}
                show={showConfirmationModal}
                onClose={handleCloseConfirmationModal}
                buttonVariant="danger"
            />
            <FindingsModal
                show={show}
                onClose={handleCloseModal}
                onSave={handleSave}
                defaultFindingType={findingData}
            />
        </>
    );
};

export default FindingsConfigTable;
